import React, { useEffect } from "react";
import styles from "./demowishes.module.css";
import Navbar from "../../Components/Navbar";
import coverImage from "../../Assets/Images/demoCoverImage.png";
import qrImage from "../../Assets/Images/demoQr.png";
import videoCover from "../../Assets/Images/demoVideoCover.png";
import playButton from "../../Assets/Images/demoPlayButton.svg";
import FooterShort from '../../Components/FooterShort'
import NavbarNew from "../../Components/NavbarNew";

function LandingWishes() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="containFull">
      <NavbarNew />
      <div className={styles.container}>
        <div className={styles.mainDiv}>
          <div className={styles.childDiv1}>
            <p className="font_semibold_24">
              Experience Memshots Digital Autograph
            </p>
            <div className={styles.noteDiv}>
              <p className="font_normal_13 opacity-[0.6]">
                Assuming that you have purchased the wishes module and the QR
                code provided by the module has been placed for the attendees to
                scan
              </p>
              <div>
                <p className="font_semibold_10 text-[#fff] ">Note</p>
              </div>
            </div>
            <div className={`${styles.stepsDiv} no-scrollbar`}>
              <div className={styles.item1}>
                <div className="flex justify-between">
                  <p className="font_semibold_16">Step 01</p>
                  <img src={qrImage} alt="" />
                </div>
                <p className="font_semibold_21_18 mt-[60px]">
                  Attendees will scan the QR code and upload the wishes
                </p>
              </div>
              <div className={styles.item2}>
                <p className="font_semibold_16">Step 02</p>
                <p className="font_semibold_21_18">
                  Purchased owner or admin will approve the wishes after
                  verification.
                </p>
              </div>
              <div className={styles.item3}>
                <p className="font_semibold_16">Step 03</p>
                <p className="font_semibold_21_18">
                  Wishes will be available for the public to view 🎊
                </p>
              </div>
              <div className={styles.item4}>
                <img src={videoCover} alt="" />
                <div>
                  <img src={playButton} alt="" />
                  <p className="font_semibold_16">Watch Now</p>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = `https://app.memshots.com/38ryanwedsch/memories/sendwishes`;
            }}
            className={styles.childDiv2}
          >
            <div className="relative cursor-pointer">
              <img
                src={coverImage}
                alt=""
                className="h-full object-cover rounded-[24px]"
              />
              <div className={styles.absoluteDiv}>
                <p className="font_medium_16">
                  Experience Memshots Digital Autograph now.
                </p>
                <button className={styles.startDemoButton}>Start Demo</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default LandingWishes;
