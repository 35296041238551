import React, { useContext, useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router";
import AuthContext from "../../AuthContext";
import SignupPage from "../../Pages/Signup/SignupPage";
import axios from "axios";
import wishesBgImage from '../../Assets/Images/Home/wishesImage.png'


function WishTool({ setWishSelected, setSelectedWishpackage, packagePricing, wishBaseAmount }) {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);


  const handleSubmit = () => {
    navigate("buynow")
  };

  return (
    <>
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div className={styles.container}>
        <div className={styles.wishToolDiv}>
          <div className="w-full">
            <p className={`font_normal_40_32 dmSerifRegularItalic `}>
              Wish your loved ones for any special day!
            </p>
          </div>
          <div className="mt-[40px] lg:mt-[10px] w-full flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
              <p className="font_semibold_32_24">Digital Autograph</p>
              <p className="mt-[16px] font_normal_16">
              Digital Autograph is a modern, innovative way to capture and share personalized messages and signatures in the digital age.
              </p>
              <p className="mt-[12px] font_normal_16">
              Perfect for classmates' autographs, farewell messages, reviews, and any special event, digital autographs combine the authenticity of traditional signatures with the convenience of digital technology.</p>
              <p className="font_semibold_18 mt-[40px]">{`Starting at ₹ ${wishBaseAmount}`}</p>
            </div>
            <div className="mt-[20px] lg:mt-0">
              <img src={wishesBgImage} alt="" className="w-full object-cover max-w-[374px]" />
            </div>
          </div>
          <div className={styles.wishToolPricing}>
            <p className="font_bold_24">Ready to dive in?</p>
            <div className={styles.wishToolPricingSection}>
              <p className="dmSerif_italic_blue font_normal_24">Try a free demo today or buy it.</p>
              <div className="flex gap-[12px]">
                <button
                  onClick={() => {
                    navigate("onlinewishbookdemo");
                  }}
                  className={styles.tryDemoButton}
                >
                  Try Demo
                </button>
                <button
                  onClick={() => {
                    setWishSelected(true);
                    setSelectedWishpackage(packagePricing?.wish && packagePricing?.wish[0]);
                    handleSubmit();
                  }}
                  className={styles.buyNowButton}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default WishTool;
