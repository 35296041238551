import React from "react";
import styles from "../styles.module.css";

function Features() {
    return (
        <div className={styles.container}>
            <div className={styles.featuresDiv}>
                <p className="max-w-[647px] font_semibold_24 text-[#000]">
                No matter the event, giving the gift of everlasting memories is truly magical. It's like wrapping up precious moments to be cherished forever.
                </p>
                <ul>
                    <li>
                        <span className="font_semibold_21_18 text-[#575FCC]">Engaging Visual Content</span>
                        <p className="font_normal_16 text-[#000] max-w-[588px]">Make your event stand out with captivating visual content that showcases its beauty and charm. Whether it's a birthday bash, a heartfelt farewell, or a dreamy wedding, highlight the essence of the occasion with stunning images that captivate your guests' imagination.</p>
                    </li>
                    <li>
                        <span className="font_semibold_21_18 text-[#FF6D3A]">Interactive Features</span>
                        <p className="font_normal_16 text-[#000] max-w-[588px]">Interactive features help to integrate the virtual farewell party, enabling colleagues to come together and express appreciation, bidding farewell to their departing coworker in a meaningful and memorable way, despite being physically apart.</p>
                    </li>
                    <li>
                        <span className="font_semibold_21_18 text-[#098C55]">Capture Special Moments</span>
                        <p className="font_normal_16 text-[#000] max-w-[588px]">Encourage your friends and colleagues to share their favorite moments through the digital autograph, allowing everyone to cherish the memories together.</p>
                    </li>
                    <li>
                        <span className="font_semibold_21_18 text-[#9747FF]">Share Memories with Ease</span>
                        <p className="font_normal_16 text-[#000] max-w-[588px]">"Share Memories with Ease" transforms the act of sharing and reminiscing about cherished memories into a seamless and enjoyable experience, bringing people closer together through the power of nostalgia.</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Features;
