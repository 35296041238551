import React from "react";
import styles from "./home.module.css";

function Features() {
  return (
    <div className={styles.container}>
      <div className={styles.featuresDiv}>
        <p className="font_medium_52_32_32">
          One platform, infinite possibilities
        </p>
        <div className={styles.featuresSection}>
          <div>
            <p className="font_semibold_28_24_24">Unlimited Space</p>
            <p className="mt-[24px] font_normal_16">
              With Memshots, users can now send an unlimited number of wishes
              and share images and videos seamlessly. Capture and cherish every
              moment effortlessly.
            </p>
          </div>
          <div>
            <p className="font_semibold_28_24_24">Powerful AI-Feature</p>
            <p className="mt-[24px] font_normal_16">
              Unlock the Power of AI! Our online album creator features
              cutting-edge AI technology, making it easier than ever to create
              stunning albums effortlessly. Experience the future of album
              creation today!
            </p>
          </div>
          <div>
            <p className="font_semibold_28_24_24">Privacy & Security</p>
            <p className="mt-[24px] font_normal_16">
              Our Privacy Policy and Rights ensure your security and protection
              while using our website. We prioritise safeguarding your personal
              information and outline how we collect, use, and protect your
              data.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
