import React from 'react'

function About({ hotelData }) {
    return (
        <div className='containFull px-[20px] lg:px-[86px] lg:max-w-[821px] mt-[60px] lg:mt-[120px]'>
            <p className='font_semibold_40_24 clashdisplay_semibold text-[#1F2797] mb-[16px]'>About {hotelData?.hotelName}</p>
            <p className='font_normal_21_16 text-[#1F2797]'>{hotelData?.about?.hotelBioSection1}</p>
            <p className='font_normal_21_16 text-[#1F2797] mt-[4px] lg:mt-[8px]'>{hotelData?.about?.hotelBioSection2}</p>
            <a href={hotelData?.about?.hotelWebsite} target='_blank'><button
                className='rounded-[60px] bg-[#FFF] px-[24px] py-[12px] text-[#1F2797] font_semibold_16 mt-[16px] cursor-pointer hover:opacity-[0.9]'>
                Explore More
            </button></a>
        </div>
    )
}

export default About