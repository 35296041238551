import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Cover from "../Components/Pitch/Gifts/Cover";
import CoverImage from "../Components/Pitch/Gifts/CoverImage";
import Testimonials from "../Components/Pitch/Gifts/Testimonials";
import backToTop from "../Assets/Images/Home/backToTop.svg";
import Features from "../Components/Pitch/Gifts/Features";
import FooterShort from "../Components/FooterShort";
import imageLeft from "../Assets/Images/Pitch/giftsGrpahicsImgLeft.png";
import imageRight from "../Assets/Images/Pitch/giftsGrpahicsImgRight.png";
import ProductsForGifts from "../Components/Pitch/Gifts/ProductsForGifts";
import NavbarNew from "../Components/NavbarNew";

function PitchForGifting() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="containFull cursor-default">
      <NavbarNew />
      <Cover
        title={"Memshots for Gifting Friends & Family"}
        content={`Looking for a unique and meaningful gift for your loved ones ?
       Memshots offers the perfect solution with our personalized gift that
       captures the essence of those precious moments - birthdays,
       anniversaries, or any milestone worth celebrating.`}
      />
      <CoverImage imageLeft={imageLeft} imageRight={imageRight} />
      <ProductsForGifts />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Testimonials
        title={
          " Everyone is sending Memshots to their loved ones on their special day."
        }
        testimonialOne={`"Memshots' wedding album gifted by my brother keeps our memories alive. Flipping through it brings joy every time. Grateful for this thoughtful gift!" - Vivek`}
        testimonialTwo={
          '"Memshots for my birthday were a delight! Stunning photos shared effortlessly with loved ones. A gift that keeps giving joy! Thanks, Memshots!" - Megha'
        }
      />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Features />
      <FooterShort />
      <div
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        className="fixed bottom-[35px] right-[10px] cursor-pointer"
      >
        <img
          src={backToTop}
          alt=""
          className="w-[32px] lg:w-[52px] h-[32px] lg:h-[52px] backToTopShadow rounded-full"
        />
      </div>
    </div>
  );
}

export default PitchForGifting;
