import React from "react";
import styles from "./home.module.css";
import faceDetectionImg from "../../Assets/Images/Home/faceDetectionImg.png";
import birthdaypartyFDIcon from "../../Assets/Images/Home/birthdaypartyFDIcon.svg";
import corporateEventFDIcon from "../../Assets/Images/Home/corporateEventFDIcon.svg";
import privateEventFDIcon from "../../Assets/Images/Home/privateEventFDIcon.svg";
import weddingFDIcon from "../../Assets/Images/Home/weddingFDIcon.svg";
import goToIcon from "../../Assets/Images/Home/goToIcon.svg";

function FaceDetection() {
  return (
    <div className={styles.container}>
      <div className={styles.faceDetectionDiv}>
        <div className={styles.faceDetectionTitle}>
          <p className="font_semibold_72_32">AI Photo Delivery</p>
          <p className="font_normal_16 opacity-[0.8] max-w-[436px]">
            Capture smiles effortlessly! Our tool recognizes every face,
            ensuring no moment is missed. Say hello to memories that warm your
            heart for years!
          </p>

          {/* <div className={styles.faceDetectionAbsolute}>
            <img src="shootmatesLogo.png" alt="" />
            <p>Memshots for Photographers</p>
          </div> */}
        </div>
        <div className={styles.faceDetectionContent}>
          <div className={styles.faceDetectionCardSection}>
            <div
              style={{
                background: "#17AB59",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={weddingFDIcon} alt="" />
              <p className={styles.cardHeading}>Wedding Events</p>
              <p className={styles.cardSubHeading}>
                Effortlessly preserve moments with our selfie-powered photo
                capture.
              </p>
            </div>
            <div
              style={{
                background: "#DE632E",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={birthdaypartyFDIcon} alt="" />
              <p className={styles.cardHeading}>Birthday Parties</p>
              <p className={styles.cardSubHeading}>
                Easily identify every smiling face in photos by simply snapping
                a selfie.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-[#161616]">
            <img src={faceDetectionImg} alt="" />
            <p className="font_semibold_24_21 opacity-[0.8] text-center lg:mt-[18px] max-w-[365px]">
              Your Memories and Moments come alive
            </p>
          </div>
          <div className={styles.faceDetectionCardSection}>
            <div
              style={{
                background: "#2727E6",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={corporateEventFDIcon} alt="" />
              <p className={styles.cardHeading}>Corporate Events</p>
              <p className={styles.cardSubHeading}>
                Impress at your corporate event with our reliable face detection
                feature.
              </p>
            </div>
            <div
              style={{
                background: "#9407A1",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={privateEventFDIcon} alt="" />
              <p className={styles.cardHeading}>Private Events</p>
              <p className={styles.cardSubHeading}>
                Gather party memories seamlessly with friends through quick
                selfies.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.albumButtonsSection}>
          <p className="font_bold_24 max-w-[580px]">Ready to dive in?</p>
          <div className="flex gap-[20px]">
            <a
              href="https://photographers.memshots.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
              >
                <span>Explore</span>
                <img src={goToIcon} alt="" />
              </button>
            </a>
            <a
              href="https://photographers.memshots.com/aiphotosortingdemo"
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles.buyNowButton}>Try Demo</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceDetection;
