import React from "react";
import Cover from "../../Components/StreetPhotography/Cover";
import Gallery from "../../Components/StreetPhotography/Gallery";
import Navbar from "../../Components/Navbar";

function LandingStreetPhotography({ data }) {
  return (
    <div className="containFull bg-black">
       <Navbar />
      <Cover photographerData={data} />
      <Gallery photographerData={data}/>
    </div>
  );
}

export default LandingStreetPhotography;
