import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import styles from "./PurchaseLanding.module.css";
import Context from "../../Context";
import backArrowWhite from "../../Assets/Images/backArrowWhite.svg";
import backArrowBlack from "../../Assets/Images/backArrowBlack.svg";
import { useNavigate } from "react-router-dom";
import memshotsLogo from "../../Assets/Images/memshotsLogo.png";
import { isEmpty } from "lodash";
import AuthContext from "../../AuthContext";
import { ref, uploadBytesResumable } from "firebase/storage";
import { coverImageStorage } from "../../Store/firebase-config";
import axios from "axios";
import FooterShort from "../../Components/FooterShort";
import NavbarNew from "../../Components/NavbarNew";

function PurchaseReview() {
  const {
    userTitle,
    userCoverImage,
    userdescription,
    extraApprovalAccesMail,
    selectedWishpackage,
    selectedPhotosharePackage,
  } = useContext(Context);
  const { verifyUser, currentUser, userIdToken, contextUserId } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [apiLoading, setApiLoading] = useState(false);
  const token = userIdToken || currentUser?.accessToken;
  const selectedWishAmount = selectedWishpackage?.amount || 0;
  const selectedPhotoshareAmount = selectedPhotosharePackage?.amount || 0;
  const TotalValueSum = selectedWishAmount + selectedPhotoshareAmount;

  useEffect(() => {
    verifyUser();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (isEmpty(selectedWishpackage) && isEmpty(selectedPhotosharePackage)) {
      navigate("/buynow");
    }
  }, []);

  useEffect(() => {
    !currentUser && navigate("/");
  }, [currentUser]);

  const uploadFaceImage = async (userCoverImage) => {
    setApiLoading(true);
    try {
      const file = userCoverImage;
      let randomId = Math.floor(Date.now() * Math.random());
      const fileName = `${file?.name}`;
      let faceFile = `${randomId}_${fileName}`;
      const storageRef = ref(coverImageStorage, `${contextUserId}/${faceFile}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on("state_changed", (snapshot) => {
        const progressPercentage = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      });
      await uploadTask;
      const url =
        `https://storage.googleapis.com/memshots_phototool/${contextUserId}/` +
        faceFile;
      formSubmit(url);
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmit = async (url) => {
    try {
      let apiPayload = {
        title: userTitle,
        coverImage: url,
      };
      if (!isEmpty(userdescription)) {
        apiPayload = {
          ...apiPayload,
          description: userdescription,
        };
      }
      if (
        !isEmpty(extraApprovalAccesMail) &&
        extraApprovalAccesMail !== currentUser?.email
      ) {
        apiPayload = {
          ...apiPayload,
          userAccessMail: extraApprovalAccesMail,
        };
      }
      if (!isEmpty(selectedWishpackage)) {
        apiPayload = {
          ...apiPayload,
          wishesPackage: selectedWishpackage?.package,
        };
      }
      if (!isEmpty(selectedPhotosharePackage)) {
        apiPayload = {
          ...apiPayload,
          sharedMemoriesPackage: selectedPhotosharePackage?.package,
        };
      }
      let config = {
        method: "POST",
        url: "https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshots/sharingtool/create",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: apiPayload,
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        paymentInitialise(response?.data?.albumId);
      }
    } catch (e) {
      console.log(e);
      setApiLoading(false);
    }
  };

  const paymentInitialise = async (albumId) => {
    try {
      let config = {
        method: "POST",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshots/sharingtool/initialise_payment/registration/${albumId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        window.location.href =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo?.url;
        setApiLoading(false);
      }
    } catch (err) {
      console.log(err);
      setApiLoading(false);
    }
  };

  return (
    <div className="containFull">
      <NavbarNew />
      <div className="w-full h-full flex justify-center items-center bg-[#FFF] min-h-[70vh] mb-[50px] xl:mb-[100px]">
        <div
          className={`my-[16px] md:my-[40px] ${styles.container} w-full  max-w-[430px] md:w-[95%] lg:w-[85%] xl:w-[75%] md:max-w-[1024px] bg-[#FFF] md:flex md:flex-row md:overflow-hidden`}
        >
          <div className="sticky top-[92px] md:flex-1 md:w-[50%] bg-[#4E2955] min-h-[420px] flex justify-center items-center z-[30]">
            <img
              src={backArrowWhite}
              alt=""
              className="absolute top-[20px] left-[20px] cursor-pointer"
              onClick={() => {
                navigate("/buynow");
              }}
            />
            <div className="absolute centeralign">
              <div className=" bg-white w-[200px] h-[390px] rounded-[24px] border-[6px] border-[#161616]  overflow-hidden relative">
                {
                  <img
                    src={
                      userCoverImage
                        ? URL.createObjectURL(userCoverImage)
                        : "https://images.unsplash.com/photo-1607190074257-dd4b7af0309f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHdlZGRpbmd8ZW58MHx8MHx8fDA%3D"
                    }
                    alt=""
                    className="w-full h-[85%] object-cover z-0"
                  />
                }
                <div
                  className={`absolute top-0 left-0 h-[120px] w-full ${styles.bgGradient} z-10`}
                />
                <p
                  className={`text-center w-full absolute top-[44px] text-black z-20 ${styles.cinzel700} px-[20px]`}
                >
                  {userTitle}
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex-1 md:w-[50%] bg-[#FFF] relative z-[40] mt-[-105px] md:mt-0 rounded-t-[24px] md:rounded-t-[1px] p-[20px]">
            <img src={memshotsLogo} alt="" className="w-[88px] h-[24px]" />
            <p className="mt-[32px] font_semibold_28_24 text-[#4D58E3] clashdisplay_semibold">
              Your online Memshots creation is succesfull 💚. Please complete
              your purchase by making the payment.
            </p>
            <p className="mt-[12px] font_normal_13 text-[#161616]">
              Share your Memshots with friends and family so they can add their
              own memories and make it even more special.
            </p>
            <p className="mt-[65px] font_semibold_16 text-[#161616]">
              Buy now and enjoy memshots experiences
            </p>
            <div className="w-full h-[1px] bg-[#D9D9D9] mt-[16px]"></div>
            <p className="mt-[16px] font_normal_16 text-[#161616]">
              Payment Details
            </p>
            <div className="mt-[12px] w-full">
              <table className=" w-full font_normal_13 text-[#161616]">
                <tbody>
                  <tr>
                    <td>Digital Autograph</td>
                    <td className="text-end">
                      {selectedWishpackage?.amount
                        ? selectedWishpackage?.amount
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Shared Memories Album</td>
                    <td className="text-end">
                      {selectedPhotosharePackage?.amount
                        ? selectedPhotosharePackage?.amount
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-full h-[1px] bg-[#D9D9D9] mt-[20px]"></div>
            <div className="mt-[12px] flex justify-between font_semibold_16 text-[#161616]">
              <p>Total</p>
              <p>{`₹ ${TotalValueSum}`}</p>
            </div>
            <div className="w-full h-[1px] bg-[#D9D9D9] mt-[12px]"></div>
            <div className="flex justify-end py-[20px]">
              <button
                disabled={apiLoading}
                onClick={() => {
                  uploadFaceImage(userCoverImage);
                }}
                className="bg-[#161616] font_normal_16_13 text-[#FFF]"
              >
                {`${apiLoading ? "Please Wait..." : "Pay now"}`}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default PurchaseReview;
