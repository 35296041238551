import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar";
import styles from "./PurchaseLanding.module.css";
import closeIcon from "../../Assets/Images/close_button_purchase.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import uploadImageIcon from "../../Assets/Images/uploadImageIcon.svg";
import replaceImageIcon from "../../Assets/Images/replaceImageIcon.svg";
import toolIcon from "../../Assets/Images/toolIcon.svg";
import { useContext } from "react";
import Context from "../../Context";
import { isEmpty } from "lodash";
import AuthContext from "../../AuthContext";
import axios from "axios";
import FooterShort from "../../Components/FooterShort";
import SignupPage from "../Signup/SignupPage";
import NavbarNew from "../../Components/NavbarNew";

function PurchaseLanding() {
  const {
    userTitle,
    setUserTitle,
    userCoverImage,
    setUserCoverImage,
    userdescription,
    setUserDescription,
    extraApprovalAccesMail,
    setExtraApprovalAccessmail,
    wishSelected,
    setWishSelected,
    fileShareSelected,
    setFileShareSelected,
    selectedWishpackage,
    setSelectedWishpackage,
    selectedPhotosharePackage,
    setSelectedPhotosharePackage,
  } = useContext(Context);
  const { verifyUser, currentUser, setPreviousLocation, googleSignOut } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const coverImageRef = useRef();
  const [errors, setErrors] = useState({});
  const [packagePricing, setPackagePricing] = useState({});
  const [popup, setPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const from = searchParams.get("from");

  useEffect(() => {
    if (currentUser?.isAnonymous) {
      googleSignOut();
    }
    getPackage();
    verifyUser();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPreviousLocation(`/buynow/review`);
  }, []);

  useEffect(() => {
    if (packagePricing && from === "autograph") {
      setWishSelected(true);
      setSelectedWishpackage(packagePricing?.wish && packagePricing?.wish[0]);
    }
    if (packagePricing && from === "sharedMemories") {
      setFileShareSelected(true);
      setSelectedPhotosharePackage(
        packagePricing?.photoShare && packagePricing?.photoShare[0]
      );
    }
  }, [packagePricing, from]);

  const handleWishCheckboxChange = () => {
    setWishSelected(!wishSelected);
  };

  const handleFileShareCheckboxChange = () => {
    setFileShareSelected(!fileShareSelected);
  };

  const handleButtonCoverImage = (e) => {
    coverImageRef.current.click();
  };

  const handleInputCoverImage = (e) => {
    setUserCoverImage(e.target.files[0]);
  };

  const handleCalculateValue = () => {
    const selectedWishAmount = selectedWishpackage?.amount || 0;
    const selectedPhotoshareAmount = selectedPhotosharePackage?.amount || 0;
    const value = selectedWishAmount + selectedPhotoshareAmount;
    return value;
  };

  const validateForm = () => {
    let errors = {};
    if (userTitle === "Kenny & Kendal" || userTitle === "") {
      errors.title = " please provide title";
    }
    if (!userCoverImage) {
      errors.coverImage = " please provide cover image";
    }
    if (isEmpty(selectedWishpackage) && isEmpty(selectedPhotosharePackage)) {
      errors.toolSelection = " please select any one tool";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validateErrors = validateForm();
    if (Object.keys(validateErrors).length === 0) {
      currentUser ? navigate("review") : setPopup(true);
    } else setErrors(validateErrors);
  };

  const getPackage = async () => {
    try {
      const response = await axios.get(
        `https://storage.googleapis.com/memshots_wishes/PHOTOTOOL_PACKAGES/photoToolPackages.json`
      );
      setPackagePricing(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="containFull">
      <NavbarNew />
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div className="w-full h-full flex justify-center items-center bg-[#FFF] min-h-[70vh] mb-[50px] xl:mb-[100px]">
        <div
          className={`my-[16px] md:my-[40px] ${styles.container} w-full  max-w-[430px] md:w-[95%] lg:w-[85%] xl:w-[75%] md:max-w-[1024px] bg-[#FFF] md:flex md:flex-row md:overflow-hidden`}
        >
          <div className="sticky top-[92px] md:flex-1 md:w-[50%] bg-[#4E2955] min-h-[420px] flex justify-center items-center z-[30]">
            <div className="bg-white w-[200px] h-[390px] rounded-[24px] border-[6px] border-[#161616]  overflow-hidden relative">
              {
                <img
                  src={
                    userCoverImage
                      ? URL.createObjectURL(userCoverImage)
                      : "https://images.unsplash.com/photo-1607190074257-dd4b7af0309f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHdlZGRpbmd8ZW58MHx8MHx8fDA%3D"
                  }
                  alt=""
                  className="w-full h-[85%] object-cover z-0"
                />
              }
              <div
                className={`absolute top-0 left-0 h-[120px] w-full ${styles.bgGradient} z-10`}
              />
              <p
                className={`text-center w-full absolute top-[44px] text-black z-20 ${styles.cinzel700} px-[20px]`}
              >
                {userTitle ? userTitle : "Kenny & Kendal"}
              </p>
            </div>
          </div>



          <div className="md:flex-1 md:w-[50%] bg-[#FFF] relative z-[40] mt-[-105px] md:mt-0 rounded-t-[24px] md:rounded-t-[1px]">
            <div className="flex items-center justify-between p-[20px]">
              <p className="font_semibold_21 text-[#161616]">
                Create a Memshots
              </p>
              <img
                src={closeIcon}
                alt=""
                onClick={() => {
                  setWishSelected(false);
                  setFileShareSelected(false);
                  navigate("/");
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="h-[1px] w-full bg-[#1616161A]"></div>
            <div className="px-[20px] pt-[16px] pb-[32px]">
              <div className="">
                <p className="w-full text-left font_medium_13 text-[#161616]">
                  Title & Cover Image <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  name="title"
                  onChange={(e) => setUserTitle(e.target.value)}
                  placeholder="Enter a title eg. “Kenny & Kendal”"
                  className="mt-[12px] w-full border-[1px] border-[#0000001A] rounded-[8px] p-[8px]"
                  value={userTitle}
                />
                {errors.title && (
                  <p className="font_normal_12 text-[#fc3535] mt-[5px]">
                    {errors.title}
                  </p>
                )}
              </div>
              <div className="mt-[16px] ">
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center gap-[6px] w-[50%]">
                    {userCoverImage && (
                      <img
                        src={
                          userCoverImage && URL.createObjectURL(userCoverImage)
                        }
                        alt=""
                        className="w-[36px] h-[36px] object-cover rounded-[4px]"
                      />
                    )}
                    <p className="text-left font_medium_13 text-[#161616] truncate">
                      {userCoverImage
                        ? userCoverImage?.name
                        : "Upload a cover image"}
                      <span className="text-red-500">{userCoverImage
                        ? ""
                        : " *"}</span>
                    </p>
                  </div>

                  <button
                    onClick={handleButtonCoverImage}
                    className={` ${userCoverImage
                      ? "bg-[#FFFFFF] border-[1px] border-[#3171F6] text-[#000]"
                      : "bg-[#3171F6] border-[1px] border-[#FFFFFF] text-[#FFFFFF]"
                      }  font_medium_12 flex gap-[10px] items-center shrink-0`}
                  >
                    <img
                      src={userCoverImage ? replaceImageIcon : uploadImageIcon}
                      alt=""
                    />
                    <p className="font_medium_12">
                      {userCoverImage ? "Replace Image" : "Upload Image"}
                    </p>
                  </button>
                  <input
                    ref={coverImageRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleInputCoverImage}
                  />
                </div>
                {errors.coverImage && (
                  <p className="font_normal_12 text-[#fc3535] mt-[5px]">
                    {errors.coverImage}
                  </p>
                )}
              </div>
              <div className="mt-[16px]">
                <p className="w-full text-left font_medium_13 text-[#161616]">
                  Event Description
                </p>
                <input
                  type="text"
                  name="description"
                  maxlength="150"
                  onChange={(e) => setUserDescription(e.target.value)}
                  placeholder="Enter a short description of event"
                  className="mt-[12px] w-full border-[1px] border-[#0000001A] rounded-[8px] p-[8px]"
                  value={userdescription}
                />
                <p className="font_normal_10 mt-[2px]">Letters limitted to a maximum of 150</p>
              </div>
              <div className="mt-[16px]">
                <p className="w-full text-left font_medium_13 text-[#161616]">
                  Add another email to approve wishes and memories.
                </p>
                <input
                  type="text"
                  name="email"
                  onChange={(e) => setExtraApprovalAccessmail(e.target.value)}
                  placeholder="Enter an email eg. “abc@xyz.com”"
                  className="mt-[12px] w-full border-[1px] border-[#0000001A] rounded-[8px] p-[8px]"
                  value={extraApprovalAccesMail}
                />
              </div>
            </div>
            {/* Wishes Section */}
            <div className=" border-t-[1px] border-[#0000001A] relative">
              <div className="absolute top-0 left-[20px] translateY px-[7px] py-[2px] rounded-[4px] border-[1px] bg-[#fff] border-[#0000001A]">
                <p className="font_medium_10 text-[#000]">Digital Autograph</p>
              </div>
              <div className="containFull px-[20px]">
                {!wishSelected && (
                  <div
                    onClick={handleWishCheckboxChange}
                    className={`mt-[24px] w-full ${styles.ToolContainer} flex items-center justify-between cursor-pointer`}
                  >
                    <div className="flex items-center gap-[8px]">
                      <img src={toolIcon} alt="" />
                      <p className="font_semibold_13 text-[#000]">
                        Do you like to include Digital Autograph?
                      </p>
                    </div>
                    <div className="bg-[#9747FF] rounded-[4px] px-[7px] py-[2px]">
                      <p className="font_semibold_10 text-[#FFF]">ADD</p>
                    </div>
                  </div>
                )}
                {wishSelected && (
                  <div className="mt-[24px]">
                    <div className="flex items-center w-full justify-between">
                      <p className="w-full text-left font_medium_13 text-[#161616]">
                        Choose the limit
                      </p>
                      <input
                        type="checkbox"
                        checked={wishSelected}
                        onChange={() => {
                          handleWishCheckboxChange();
                          setSelectedWishpackage({});
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-[12px] flex justify-between text-[#000]">
                      {packagePricing?.wish?.map((obj, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setSelectedWishpackage(obj);
                          }}
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "8px",
                            paddingRight: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            position: "relative",
                            backgroundColor:
                              selectedWishpackage?.limit === obj?.limit
                                ? obj?.bgColor
                                : "white",
                            borderColor:
                              selectedWishpackage?.limit === obj?.limit
                                ? obj?.borderColor
                                : "#7878784D",
                          }}
                        >
                          <div
                            className="absolute top-0 left-[8px] translateY px-[7px] py-[2px] rounded-[4px]"
                            style={{
                              backgroundColor: obj?.packageBgColor,
                            }}
                          >
                            <p className="font_semibold_10 text-[#FFF]">
                              {obj?.package}
                            </p>
                          </div>
                          <p className="font_normal_12 opacity-[0.6]">
                            {obj?.limit} authographs
                          </p>
                          <p className="mt-[12px] font_bold_16">
                            ₹ {obj?.amount}
                            <span className="font_normal_13"> only</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Photo Video Share Section */}
            <div className="mt-[26px] border-t-[1px] border-[#0000001A] relative">
              <div className="absolute top-0 left-[20px] translateY px-[7px] py-[2px] rounded-[4px] border-[1px] bg-[#fff] border-[#0000001A]">
                <p className="font_medium_10 text-[#000]">
                  Shared Memories Album
                </p>
              </div>
              <div className="containFull px-[20px]">
                {!fileShareSelected && (
                  <div
                    onClick={handleFileShareCheckboxChange}
                    className={`mt-[24px] w-full ${styles.ToolContainer} flex items-center justify-between cursor-pointer`}
                  >
                    <div className="flex items-center gap-[8px]">
                      <img src={toolIcon} alt="" />
                      <p className="font_semibold_13 text-[#000]">
                        Do you like to include Shared Memories Album?
                      </p>
                    </div>
                    <div className="bg-[#9747FF] rounded-[4px] px-[7px] py-[2px]">
                      <p className="font_semibold_10 text-[#FFF]">ADD</p>
                    </div>
                  </div>
                )}
                {fileShareSelected && (
                  <div className="mt-[24px]">
                    <div className="flex items-center w-full justify-between">
                      <p className="w-full text-left font_medium_13 text-[#161616]">
                        Choose the limit
                      </p>
                      <input
                        type="checkbox"
                        checked={fileShareSelected}
                        onChange={() => {
                          handleFileShareCheckboxChange();
                          setSelectedPhotosharePackage({});
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-[12px] flex justify-between text-[#000]">
                      {packagePricing?.photoShare?.map((obj, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setSelectedPhotosharePackage(obj);
                          }}
                          style={{
                            borderRadius: "8px",
                            borderWidth: "1px",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "8px",
                            paddingRight: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            position: "relative",
                            backgroundColor:
                              selectedPhotosharePackage?.limit === obj?.limit
                                ? obj?.bgColor
                                : "white",
                            borderColor:
                              selectedPhotosharePackage?.limit === obj?.limit
                                ? obj?.borderColor
                                : "#7878784D",
                          }}
                        >
                          <div
                            className=" absolute top-0 left-[8px] translateY px-[7px] py-[2px] rounded-[4px]"
                            style={{
                              backgroundColor: obj?.packageBgColor,
                            }}
                          >
                            <p className="font_semibold_10 text-[#FFF]">
                              {obj?.package}
                            </p>
                          </div>
                          <p className="font_normal_12 opacity-[0.6]">
                            {obj?.limit} photos
                          </p>
                          <p className="mt-[12px] font_bold_16">
                            ₹ {obj?.amount}{" "}
                            <span className="font_normal_13"> only</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full h-[1px] mt-[14px] bg-[#D9D9D9]"></div>
            <div className="w-full p-[20px]">
              <div className="flex justify-between items-center w-full">
                <p className="font_bold_16 text-[#000]">
                  {" "}
                  {`Total:  ₹${handleCalculateValue()}`}
                </p>
                <button
                  onClick={handleSubmit}
                  className="bg-[#161616] font_normal_16_13 text-[#FFF]"
                >
                  Continue
                </button>
              </div>
              {errors.toolSelection && (
                <p className="font_normal_12 text-[#fc3535] mt-[5px]">
                  {errors.toolSelection}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default PurchaseLanding;
