import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import styles from "./PurchaseLanding.module.css";
import successIcon from "../../Assets/Images/successIcon.png";
import failureIcon from "../../Assets/Images/failureIcon.png";
import copyLinkIcon from "../../Assets/Images/copyLink.svg";
import whatsappIcon from "../../Assets/Images/whatsappIcon.png";
import gmailIcon from "../../Assets/Images/gmailIcon.png";
import facebookIcon from "../../Assets/Images/facebookIcon.png";
import twitterIcon from "../../Assets/Images/twitterIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../AuthContext";
import PageLoading from "../../Components/PageLoading/PageLoading";
import FooterShort from "../../Components/FooterShort";
import NavbarNew from "../../Components/NavbarNew";

function PurchaseSuccess() {
    const { verifyUser, currentUser, userIdToken } = useContext(AuthContext);
    const { albumId } = useParams();
    const [copied, setCopied] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState("");
    const token = userIdToken || currentUser?.accessToken;
    const [albumUrl, setAlbumUrl] = useState("Please wait...");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        verifyUser();
    }, []);

    useEffect(() => {
        if (albumId && currentUser) {
            fetchPaymentResponse();
        }
    }, [albumId, currentUser]);

    const fetchPaymentResponse = async () => {
        setLoading(true);
        try {
            let config = {
                method: "GET",
                url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshots/sharingtool/phototool/${albumId}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios(config);
            setPaymentStatus(response?.data?.paymentStatus);
            setAlbumUrl(response?.data?.url);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    function copyLink() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, [1000]);
        navigator.clipboard.writeText(albumUrl);
        window.document.execCommand("copy");
    }
    const shareToWhatsapp = () => {
        const encodedContent = encodeURIComponent(albumUrl);
        const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(
            navigator.userAgent
        );
        if (isMobileDevice) {
            const url = `https://wa.me/?text=${encodedContent}`;
            window.open(url, "_blank");
        } else {
            const url = `https://web.whatsapp.com/send?text=${encodedContent}`;
            window.open(url, "_blank");
        }
    };
    const shareToFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${albumUrl}`);
        return false;
    };
    const shareToTwitter = () => {
        window.open(`http://twitter.com/share?url=${albumUrl}`);
    };
    const shareToGmail = () => {
        window.location.href = `mailto:?body=${albumUrl}`;
    };

    return (
        <div className="containFull">
            <NavbarNew />
            {loading ? (
                <PageLoading />
            ) : (<>
                <div className='w-full h-full flex justify-center items-center bg-[#FFF] min-h-[70vh] mb-[50px] xl:mb-[100px]'>
                    <div className="w-full h-full flex justify-center  bg-[#FFF]">
                        <div
                            className={` mt-[120px] mb-[30px] ${styles.container} w-full  max-w-[350px]  bg-[#FFF] flex flex-col `}
                        >
                            <div className=" w-full flex flex-col justify-center items-center ">
                                <img
                                    src={
                                        paymentStatus === "PAYMENT_SUCCESS"
                                            ? successIcon
                                            : failureIcon
                                    }
                                    alt=""
                                    className="w-[170px] h-[112px] mt-[-70px]"
                                />
                                <p className="font_medium_16 text-[#3171F6] text-center w-full">
                                    {paymentStatus === "PAYMENT_SUCCESS" ? "SUCCESS" : "FAILED"}
                                </p>
                            </div>
                            <div className="px-[20px] pt-[20px] pb-[16px] w-full text-center text-[#000]">
                                <p className="font_semibold_18 clashdisplay_semibold ">
                                    {paymentStatus === "PAYMENT_SUCCESS"
                                        ? "Your online Memshots creation is successfully completed 💚"
                                        : "Don’t worry we will check the payment status and get back to you"}
                                </p>
                                <p className="mt-[16px] font_normal_13 opacity-[0.6]">
                                    {paymentStatus === "PAYMENT_SUCCESS"
                                        ? "Share with your friends and families and enjoy the shared memories."
                                        : ""}
                                </p>
                            </div>
                            <div className="w-full flex justify-center mb-[16px]">
                                <button
                                    onClick={() => { navigate('/userprofile') }}
                                    className={`${styles.tryDemoButton} w-fit`}>Show My Orders</button>
                            </div>
                            <div className="h-[1px] w-full bg-[#D9D9D9]"></div>
                            {paymentStatus === "PAYMENT_SUCCESS" ? (
                                <div className="px-[20px] pt-[16px] pb-[38px] text-center text-[#000] flex flex-col items-center">
                                    <p className="font_semibold_13 ">
                                        Share this link to your socials
                                    </p>
                                    <div
                                        className=" w-[100%] bg-[#EFF1FF] p-[8px] mt-[16px] rounded-[6px] cursor-pointer"
                                        onClick={copyLink}
                                        title="Copy to clipboard"
                                    >
                                        <p className="text-[#10101099] font_normal_13 truncate">
                                            {albumUrl}
                                        </p>
                                    </div>
                                    <div
                                        onClick={copyLink}
                                        title="Copy to clipboard"
                                        className="mt-[16px] flex items-center gap-[6px] rounded-[8px] border-[1px] border-[#E4E4E4] px-[10px] py-[4px] cursor-pointer"
                                    >
                                        <img src={copyLinkIcon} alt="" />
                                        <p className="font_medium_12 text-[#000]">
                                            {copied ? "link copied" : "copy link"}
                                        </p>
                                    </div>
                                    <p className="mt-[24px] font_normal_13 text-[#10101099]">
                                        Click on an icon below to go directly to your social accounts,
                                        then share your Memshots link on your socials.
                                    </p>
                                    <div className="mt-[30px] flex items-center gap-[20px]">
                                        <img
                                            onClick={shareToWhatsapp}
                                            src={whatsappIcon}
                                            alt=""
                                            className="cursor-pointer"
                                        />
                                        <img
                                            onClick={shareToGmail}
                                            src={gmailIcon}
                                            alt=""
                                            className="cursor-pointer"
                                        />
                                        <img
                                            onClick={shareToFacebook}
                                            src={facebookIcon}
                                            alt=""
                                            className="cursor-pointer"
                                        />
                                        <img
                                            onClick={shareToTwitter}
                                            src={twitterIcon}
                                            alt=""
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="px-[20px] pt-[16px] pb-[38px] flex justify-center">
                                    <button
                                        onClick={() => {
                                            window.location.href = "https://www.memshots.com/";
                                        }}
                                        className={styles.buyNowButton}
                                    >
                                        Try Again
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <FooterShort />
            </>
            )}

        </div>
    );
}

export default PurchaseSuccess;
