// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJIjwBkj3AB00mBoG9HjawknJH6VZbFE0",
  authDomain: "memshots-380011.firebaseapp.com",
  projectId: "memshots-380011",
  storageBucket: "memshots-380011.appspot.com",
  messagingSenderId: "952254678432",
  appId: "1:952254678432:web:3b411d81d5aabce4b510c5",
  measurementId: "G-SMDHZDVL6C"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app)
export const storage = getStorage(app);
export const mediaStorage = getStorage(app, 'wedding-albums')
export const faceStorage = getStorage(app, 'memshots_face_requests')
export const wishesStorage = getStorage(app, 'memshots_wishes')
export const photoShareStorage = getStorage(app, 'memshots_sharedmemories')
export const coverImageStorage = getStorage(app, 'memshots_phototool')
export const invitationImageStorage = getStorage(app, 'memshots_invitation')

