import React from "react";
import imageLeft from "../../Assets/Images/Home/coverLeft.png";
import imageRight from "../../Assets/Images/Home/coverRight.png";
import styles from "./home.module.css";

function Cover() {

  return (
    // <div className={styles.container}>
    //   <div className={styles.coverDiv}>
    //     <p className="font_semibold_60_52_40 max-w-[800px]">
    //       Buy
    //       <span className="dmSerifRegularItalic"> memories </span>
    //       for your friends on their special day.
    //     </p>
    //     <p className="mt-[16px] sm:mt-[14px] font_normal_16 max-w-[500px]">
    //     Gift them the new kind of happiness
    //     </p>
    //     <div className={styles.coverImageDiv}>
    //       <div>
    //         <img src={imageLeft} alt="" className="object-cover" />
    //       </div>
    //       <div className="hidden lg:flex">
    //         <img src={imageRight} alt="" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className={styles.coverContainer}>
      <div
        className={styles.backgroundContainerCover}
      >
        <div className={styles.pitchTitle}>
          <p className="font_semibold_120_62 ">MEMSHOTS</p>
          <p className="font_normal_16 opacity-[0.8] max-w-[514px] text-center">
            The way you capture, organise, and share your most cherished
            memories.
          </p>
          {/* <div className={styles.pitchAbsolute}>Best one ever!</div> */}
        </div>

        <p className="absolute bottom-[20px] lg:bottom-[30px] left-[50%] translateX text-[#F6F6F6] opacity-[0.6]">Scroll to explore more</p>
      </div>
    </div >
  );
}

export default Cover;
