import React from "react";
import Navbar from "../../Components/Navbar";
import Events from "../../Components/Home/Events";
// import ClientsGrid from "../../Components/Clients/ClientsGrid";
import Clients from "../../Components/Home/Clients";
import NavbarNew from "../../Components/NavbarNew";

export default function ClientsPage() {
  return (
    <div>
      <NavbarNew />
      <Clients wrap="wrap" />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Events />
    </div>
  );
}
