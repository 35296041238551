import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Transaction = () => {
  const { transactionId } = useParams();
  const [amount, setAmount] = useState("");
  const [transactionCode, setTransactionCode] = useState("");
  const [transactionIdFromAPI, setTransactionIdFromAPI] = useState("");

  useEffect(() => {
    getPaymentStatus();
  }, []);

  const getPaymentStatus = async () => {
    try {
      const config = {
        method: "GET",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesPayments/api/shootmates/payments/phonepeStatusMemshots/${transactionId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const responseFromPaymentStatus = await axios(config);
      const { data } = responseFromPaymentStatus;
      setAmount(data?.paymentPayload?.data?.amount / 100);
      setTransactionCode(data?.paymentPayload?.code);
      setTransactionIdFromAPI(data?.paymentPayload?.data?.transactionId);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <div className="max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4">
          <div className="font-bold text-lg mb-2">Payment Details</div>
          <div>
            <span className="font-bold">Amount:</span> {amount} Rs.
          </div>
          <div>
            <span className="font-bold">Transaction ID:</span>{" "}
            {transactionIdFromAPI}
          </div>
          <div>
            <span className="font-bold">Code:</span> {transactionCode}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
