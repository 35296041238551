import React from 'react'
import findPhotosIcon from '../../Assets/Images/findPhotosIcon.svg'

function Cover({ hotelData }) {



    return (
        <div className='containFull'>
            <div className='relative h-[530px] lg:h-[630px]'>
                <img src={hotelData?.cover?.coverImage} alt="" className='containFull object-cover' />
                <div className='absolute centeralign flex flex-col items-center'>
                    <img src={hotelData?.cover?.coverLogo} alt="" className='object-cover w-[202px] h-[84px]' />
                    <p className='font_normal_12 text-[#FFF] text-center w-[350px]  lg:w-[445px]'>{hotelData?.cover?.hotelDescription}</p>
                </div>
                <div className='absolute bottom-[2.7%] lg:bottom-[8.73%] horizontalCenterAlign  bg-[#1F2797E6] flex flex-col md:flex-row px-[20px] lg:px-[32px] py-[20px] lg:py-[24px] gap-[20px] lg:gap-[30px] items-center rounded-[12px] w-[350px] md:w-[690px] lg:w-[807px]'>
                    <p className='font_bold_28_21 clashdisplay_bold text-[#FFF] whitespace-normal'>Get your photos, in one click, simple step away ! Try it</p>
                    <a href={hotelData?.cover?.faceDetectionUrl} target='_blank'>
                        <div className='flex px-[24px] py-[12px] gap-[8px] items-center rounded-[40px] bg-[#FFF] cursor-pointer hover:opacity-[0.9]'>
                            <p className='font_semibold_16 text-[#000] text-nowrap'>Find your photos</p>
                            <img src={findPhotosIcon} alt="" className='object-cover w-[21px] h-[24px] mr-[20px]' />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Cover