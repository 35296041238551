import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterShort from "../Components/FooterShort";

function Poster() {
  const [data, setData] = useState({});
  const [filerSelected, setFilterSelected] = useState({});

  useEffect(() => {
    posterData();
    window.scrollTo(0, 0);
  }, []);

  const posterData = async () => {
    try {
      const response = await axios.get("/Json/Poster.json");
      setData(response.data);
      setFilterSelected(response.data.poster[0]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="containFull">
      <Navbar />
      <div className="w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[50px] xl:mb-[100px]">
        <div className="w-full flex flex-col justify-center items-center text-center">
          <p className="mt-[16px] xl:mt-[30px] font_semibold_40_30 text-[#161616]">
            Poster for your memories
          </p>
          <p className="mt-[16px] xl:mt-[24px] font_normal_21_16 text-[#161616] px-[50px] xl:px-0">
            Create your poster with beautiful background
          </p>
        </div>
        <div className="flex w-full gap-[20px] mt-[60px] xl:mt-[120px] overflow-x-scroll overflow-y-hidden no-scrollbar">
          {data?.poster?.map((obj, i) => (
            <div
              onClick={() => {
                setFilterSelected(obj);
              }}
              key={i}
              className={`shrink-0 cursor-pointer hover:opacity-[0.8] rounded-[52px] ${
                filerSelected?.title === obj?.title
                  ? "bg-white text-red-400"
                  : "bg-red-400 text-white"
              } border-[1px] border-red-400 px-[24px] py-[6px] min-w-[120px] text-center`}
            >
              <p className="font_medium_16_14">{obj?.title}</p>
            </div>
          ))}
        </div>
        <div className="w-full mt-[24px] lg:mt-[48px] grid grid-cols-2 lg:grid-cols-3 gap-[12px] lg:gap-[24px]">
          {filerSelected?.backgrounds?.map((obj, i) => (
            // <a href={`https://app.goviral.world/poster/${filerSelected?.slugId}`} target='_blank' >
            <Link
              to={`https://app.goviral.world/poster/${filerSelected?.slugId}`}
            >
              <div key={i} className="w-full h-full aspect-square">
                <img
                  src={obj}
                  alt=""
                  className="w-full h-full object-cover cursor-pointer hover:opacity-[0.8]"
                />
              </div>
            </Link>
            // </a>
          ))}
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default Poster;
