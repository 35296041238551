import React, { useEffect, useState } from "react";
import NextIconMobile from "../../Assets/Images/galleryNextMobile.svg";
import NextIconDesktop from "../../Assets/Images/galleryNextDesktop.svg";
import PrevIconMobile from "../../Assets/Images/galleryPreviousMobile.svg";
import PrevIconDesktop from "../../Assets/Images/galleryPreviousDesktop.svg";
import close from '../../Assets/Images/closeIcon.svg'
import styles from "../styles/styles.module.css";
import ReactPlayer from "react-player";

function GalleryPopup({ setGalleryPopup, data, imageIndex }) {
    const [currentItem, setCurrentItem] = useState(imageIndex);
    const [popupImageData, setPopupImageData] = useState("");
    let lengthFinder = data;


    useEffect(() => {
        setPopupImageData(data && data[currentItem]);
    }, [currentItem, data]);

    const handleNext = (e) => {
        setPopupImageData("");
        setCurrentItem((prev) => (prev === data?.length - 1 ? 0 : prev + 1));
    };

    const handlePrevious = (e) => {
        setPopupImageData("");
        setCurrentItem((prev) => (prev === 0 ? data?.length - 1 : prev - 1));
    };

    const NextImage = (innerWidth) => {
        if (innerWidth <= 640) {
            return NextIconMobile;
        } else {
            return NextIconDesktop;
        }
    };

    const PreviousImage = (innerWidth) => {
        if (innerWidth <= 640) {
            return PrevIconMobile;
        } else {
            return PrevIconDesktop;
        }
    };

    return (
        <div className={styles.popup}>
            <div className="flex  top-[20px] right-[20px]  justify-end items-center absolute z-50">
                <img
                    src={close}
                    alt=""
                    className="w-[25px] md:w-[30px] h-[25px] md:h-[30px] cursor-pointer"
                    onClick={() => {
                        setGalleryPopup(false);
                    }}
                />
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center gap-[60px] relative w-[100%] h-[100%]">
                {popupImageData?.type == 'image' ?
                    <img className={styles.theimgFace} src={popupImageData?.thumbnailImage} alt="" /> :
                    <div className="h-[100%] w-[100%] overflow-hidden flex items-center justify-center">
                        <ReactPlayer
                            controls={true}
                            width="80%"
                            height="80%"
                            url={popupImageData?.videoUrl}
                            style={{
                                
                            }}
                        />
                    </div>}
            </div>
            {currentItem != 0 && (
                <img
                    className={styles.next}
                    onClick={() => handlePrevious(currentItem)}
                    src={PreviousImage(window.innerWidth)}
                    alt=""
                />
            )}
            {currentItem != lengthFinder?.length - 1 && (
                <img
                    className={styles.previous}
                    onClick={() => handleNext(currentItem)}
                    src={NextImage(window.innerWidth)}
                    alt=""
                />
            )}
        </div>
    )
}

export default GalleryPopup;
