import React from "react";
import styles from "./ProgressBand.module.css";

export default function ProgressBandBlack({ value }) {
  const progressStyle = {
    width: `${(value / 100) * 100}%`,
    height: "4px",
    background: `#000`,
  };
  return (
    <div className={styles.progressContainerBlack}>
      <div className={styles.progressBarBlack} style={progressStyle}></div>
    </div>
  );
}
