import React from "react";
import styles from "../styles.module.css";

function CoverImage({ imageLeft, imageRight }) {
  return (
    <div className={styles.containerColor}>
      <div className={styles.coverImageDiv}>
        <div>
          <img src={imageLeft} alt="" className="object-cover" />
        </div>
        <div className="hidden lg:flex">
          <img src={imageRight} alt="" className="object-cover" />
        </div>
      </div>
    </div>
  );
}

export default CoverImage;
