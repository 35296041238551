import React, { useState } from "react";
import styles from "./InvitationPurchase.module.css";
import venueIcon from "../../Assets/Images/InitationPurchase/venueIcon.png";
import directionIcon from "../../Assets/Images/InitationPurchase/directionIcon.png";

function DateTimeVenue({ section, setTemplateJson, index, preview }) {
  const handleAddMoreVenues = (e) => {
    setTemplateJson((prev) => {
      const newArray = prev?.sections;
      const newObject = newArray[index];
      if (newObject?.sectionData?.venues?.length === 0) {
        newObject.sectionData.venues.push({}, {});
      } else newObject.sectionData.venues.push({});
      newArray[index] = newObject;
      return {
        ...prev,
        sections: newArray,
      };
    });
  };

  return (
    <div className="pb-[35px] px-[20px] ">
      {preview ? (
        section?.sectionData?.venues?.length > 0 && (
          <div>
            <p className="font_bold_18 text-[#FF4545] opacity-[0.9] w-full text-center pt-[20px] lg:pt-[60px]">
              {section?.sectionData?.venues?.length > 1 ? "Venues" : "Venue"}
            </p>
            <div className="flex flex-wrap gap-[20px] justify-center mt-[34px]">
              {section?.sectionData?.venues?.map((obj, i) => {
                return (
                  <div
                    key={i}
                    className={`w-[335px] lg:w-[380px] md:px-[20px] flex flex-col items-center py-[40px] text-center ${styles.invitationBox} `}
                  >
                    {obj?.eventTitle && <img src={venueIcon} alt="" />}
                    <span className={styles.previewEventTitle}>
                      {obj?.eventTitle}
                    </span>
                    {obj?.eventDate && (
                      <span
                        style={{
                          marginTop: "40px",
                        }}
                        className={styles.previewDateTimeHead}
                      >
                        Date & Time
                      </span>
                    )}
                    <span
                      style={{
                        marginTop: "16px",
                      }}
                      className={styles.previewDate}
                    >
                      {obj?.eventDate}
                    </span>
                    <span
                      style={{
                        marginTop: "8px",
                      }}
                      className={styles.previewDate}
                    >
                      {obj?.eventTime}
                    </span>
                    {obj?.addressLineOne && (
                      <span
                        style={{
                          marginTop: "40px",
                        }}
                        className={styles.previewDateTimeHead}
                      >
                        Venue Address
                      </span>
                    )}
                    <span
                      style={{
                        marginTop: "16px",
                      }}
                      className={styles.previewDate}
                    >
                      {obj?.addressLineOne}
                    </span>
                    <span
                      style={{
                        marginTop: "8px",
                      }}
                      className={styles.previewDate}
                    >
                      {obj?.addressLineTwo}
                    </span>
                    {obj?.directionURL && (
                      <a href={obj?.directionURL} target="_blank">
                        <button
                          style={{
                            marginTop: "20px",
                          }}
                          className={`${styles.getDirectionButton}`}
                        >
                          <span className="shrink-0">Direction</span>
                          <img src={directionIcon} alt="" />
                        </button>
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )
      ) : (
        <>
          {section?.sectionData?.venues?.length === 0 ? (
            <Venue
              obj={{}}
              i={0}
              setTemplateJson={setTemplateJson}
              index={index}
            />
          ) : (
            section?.sectionData?.venues?.map((obj, i) => (
              <div key={i} className="mt-[35px]">
                <Venue
                  obj={obj}
                  i={i}
                  setTemplateJson={setTemplateJson}
                  index={index}
                />
              </div>
            ))
          )}

          {/* {section?.sectionData?.venues?.map((obj, i) => (
            <div key={i} className="mt-[35px]">
              <Venue
                obj={obj}
                i={i}
                setTemplateJson={setTemplateJson}
                index={index}
              />
            </div>
          ))} */}
          <button
            onClick={handleAddMoreVenues}
            className={styles.addVenuesButton}
          >
            Add More Venues
          </button>
        </>
      )}
    </div>
  );
}

export default DateTimeVenue;

function Venue({ obj, i, setTemplateJson, index }) {
  const handleInputChange = (e) => {
    setTemplateJson((prev) => {
      const newArray = prev?.sections;
      let newObject = newArray[index];
      const venueArray = newArray[index]?.sectionData?.venues;
      const sectionObject = obj;
      sectionObject[e.target.id] = e.target.value;
      venueArray[i] = sectionObject;
      newObject.sectionData = { ...newObject?.sectionData, venues: venueArray };
      newArray[index] = newObject;
      return {
        ...prev,
        sections: newArray,
      };
    });
  };

  return (
    <div className={styles.dottedSectionContainer}>
      <input
        onChange={handleInputChange}
        id="eventTitle"
        className={`${styles.inputField} mt-[30px]`}
        type="text"
        placeholder='Enter venue title. "Eg. Reception"'
        value={obj?.eventTitle}
      />
      <span className="mt-[24px] font_semibold_16 text-[#2B1745]">
        Date & Time
      </span>
      <input
        onChange={handleInputChange}
        id="eventDate"
        className={` ${styles.inputField} mt-[18px]`}
        type="text"
        placeholder='Enter event date. "Eg. 14 Feb 2024,Wednesday"'
        value={obj?.eventDate}
      />
      <input
        onChange={handleInputChange}
        id="eventTime"
        className={` ${styles.inputField} mt-[16px]`}
        type="text"
        placeholder='Enter event time. "Eg. 6.30 PM"'
        value={obj?.eventTime}
      />
      <span className="mt-[40px] font_semibold_16 text-[#2B1745]">
        Venue Address
      </span>
      <input
        onChange={handleInputChange}
        id="addressLineOne"
        className={` ${styles.inputField} mt-[18px]`}
        type="text"
        placeholder='Enter happening place. "Eg. Taj Lake Palace"'
        value={obj?.addressLineOne}
      />
      <input
        onChange={handleInputChange}
        id="addressLineTwo"
        className={` ${styles.inputField} mt-[16px]`}
        type="text"
        placeholder='Enter location. "Eg. Udaipur, Rajasthan 313001"'
        value={obj?.addressLineTwo}
      />
      <button className={`${styles.getDirectionButton} mt-[20px] mb-[40px]`}>
        <span className="shrink-0">Get Direction</span>
        <img src={directionIcon} alt="" />
        <input
          onChange={handleInputChange}
          id="directionURL"
          className={` ${styles.inputField}`}
          type="text"
          placeholder="Paste Map URL"
          value={obj?.directionURL}
        />
      </button>
      <div className={styles.dottedSectionTitleAbsolute}>
        <p>Venue Details</p>
      </div>
    </div>
  );
}
