import React from "react";
import styles from "./PageLoading.module.css";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../Assets/loadingGoviral.json";


export default function PageLoading() {
  
  return (
    <div className={styles.mainDiv}>
      <div className="w-full  flex align-center justify-center">
        <Lottie
          loop
          animationData={loadingAnimation}
          play
          style={{ width: 100, height: 100 }}
        />
      </div>
    </div>
  );
}
