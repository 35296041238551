import React from "react";
import styles from "./home.module.css";
import backgroundImage from "../../Assets/Images/Home/memoriesBg.png";
import { useNavigate } from "react-router-dom";

function PhotoShareToolDemo() {
  const navigate = useNavigate();
  return (
    <div className={styles.containerBlack}>
      <div className={styles.photoShareToolDemoDiv}>
        <img
          src={backgroundImage}
          alt=""
          className="w-full h-full object-cover"
        />
        <div className={styles.photoShareToolDemoContainer}>
          <div className={styles.photoShareToolDemoContainerSection1}>
            <p className="font_light_40_32">
              Create a memories page with no limits
            </p>
            <p className="font_normal_16 opacity-[0.6]">
              What kind of memories would you like to create
            </p>
          </div>
          <div className={styles.photoShareToolDemoContainerSection2}>
            <div className="font_medium_16">
              <p>Wedding</p>
              <p>Birthday</p>
              <p>Event</p>
              <p>College</p>
              <p>Farewell</p>
              <p>Success party</p>
            </div>
            <p
              onClick={() => {
                navigate("sharedmemoriesdemo");
              }}
              className="font_normal_16"
            >
              Anyone can build a memories page with no limit -{" "}
              <span className="underline cursor-pointer">Try a Demo</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoShareToolDemo;
