import React, { useEffect, useState } from "react";
import Cover from "../../Components/Hotels/Cover";
import Events from "../../Components/Hotels/Events";
import About from "../../Components/Hotels/About";
import Gallery from "../../Components/Hotels/Gallery";
import Services from "../../Components/Hotels/Services";
import Navbar from "../../Components/Navbar";

function Landing({ data }) {
  return (
    <div className="containFull">
       <Navbar />
      <Cover hotelData={data} />
      <Services hotelData={data} />
      <Events hotelData={data} />
      <About hotelData={data} />
      <Gallery hotelData={data} />
    </div>
  );
}

export default Landing;
