import React from 'react'
import rightArrow from '../../Assets/Images/servicesRightArrow.svg'

function Events({ hotelData }) {
  return (
    <div className='containFull px-[20px] lg:px-[86px] mt-[60px] lg:mt-[120px]'>
      <p className='font_semibold_40_24 clashdisplay_semibold text-[#1F2797] mb-[24px] lg:mb-[48px]' >Our Recent Events & Parties</p>
      <div className='grid md:grid-cols-2 md:gap-x-[50px] lg:gap-x-[100px] gap-y-[30px] lg:gap-y-[40px]'>
        {
          hotelData?.events?.map((obj, i) => {
            return (
              <div key={i} className='flex flex-col gap-[16px] lg:gap-[20px]'>
                <div>
                  <p className='font_bold_24_18 text-[#1F2797]'>{obj?.eventName}</p>
                </div>
                <div className='flex items-center gap-[10px]'>
                  <div className='flex flex-col items-center px-[14px] lg:px-[22px] py-[4px] lg:py-[12px] bg-[#FFF] rounded-[8px]'>
                    <p className='font_semibold_32_24 text-[#1F2797]'>{obj?.eventDate}</p>
                    <p className='font_normal_16_13 text-[#1F2797]'>{obj?.eventDay}</p>
                  </div>
                  <div className='flex gap-[10px] overflow-y-hidden overflow-x-auto no-scrollbar mx-auto'>
                    {
                      obj?.images?.map((obj, i) => {
                        return (
                          <div key={i} className='shrink-0 '>
                            <img src={obj?.thumbnailImage} alt="" className='w-[60px] lg:w-[80px] h-[60px] lg:h-[80px] rounded-[8px] object-cover' />
                          </div>
                        )
                      })
                    }
                  </div>
                  <a href={obj?.eventUrl} target='_blank'>
                    <div className='w-[40px] h-[40px] ml-[30px] lg:ml-[2px]'>
                      <img src={rightArrow} alt="" className='cursor-pointer w-[40px] h-[40px]' />
                    </div>
                  </a>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Events