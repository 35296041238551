import React, { useContext, useEffect, useRef } from "react";
import styles from "./home.module.css";
import introCoverImg from '../../Assets/Images/Home/introCoverImg.png'
import Context from "../../Context";

function Introduction() {

  const targetRef = useRef()
  const {  setBackground } = useContext(Context)

  const handleScroll = () => {
    if (targetRef.current) {
      const scrollPosition = window.scrollY;
      const targetPosition = targetRef.current.offsetTop;

      if (scrollPosition >= targetPosition) {
        setBackground('rgba(255,255,255)'); // Change to your desired color
      } else {
        setBackground('transparent');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
    ref={targetRef}
      className={styles.container}>
      <div className={styles.indroductionDiv}>
        <div className={styles.indroductionCover}>
          <div className="flex-1">
            <p className="font_semibold_52_32">Capturing Moments, Delivering Memories Instantly</p>
            <p className="font_semibold_32_16  lg:max-w-[500px] "></p>
          </div>
          <div className="flex-1">
            <img src={introCoverImg} alt="introCoverImg" className="" />
          </div>
        </div>
        <p className="font_normal_21_16 mt-[30px] lg:mt-[80px] max-w-[670px]">We're constantly innovating to create IT products that make life easier for event planners and photographers..</p>
        <div className={styles.introProductsTitle}>
          <p className="font_semibold_52_32 text-[#2C3EC4] max-w-[760px]">Best <span className="dmSerifRegularItalic">tools </span>ever made for event photographers.</p>
          <p className="max-w-[500px] font_normal_16">Our intuitive platform makes it easy to upload, organize, and share best photos – leaving you free to enjoy every moment of any special day.</p>
        </div>
      </div>

    </div >
  );
}

export default Introduction;
