import React from 'react'
import memshotsLogoWhite from '../../Assets/Images/memshotsLogoWhite.png'
import instaLogo from '../../Assets/Images/Home/instaLogoBlack.svg'
import linkedInLogo from '../../Assets/Images/Home/linkedInBlack.svg'
import { useNavigate } from 'react-router'
import styles from './home.module.css'

function Footer() {
    const navigate = useNavigate()
    return (
        <div className={styles.containerBlackPadding}>
            <div className={styles.footerDiv}>
                <div className={styles.footerContent}>
                    <img src={memshotsLogoWhite} alt="" className='w-[120px] h-[34px] sm:w-[220px] sm:h-[44px] object-contain' />
                    <div className={styles.footerContentSection2}>
                        <div >
                            <p>Memshots</p>
                            <p className='mt-[32px]  text-[#787878] cursor-pointer'>Digital Autograph</p>
                            <p className={styles.footerContentSectionText}>Shared Memories Album</p>
                            <p className={styles.footerContentSectionText}>Online Invitation</p>
                            <p className={styles.footerContentSectionText}>AI Photo Delivery</p>
                            <p className={styles.footerContentSectionText}>Signature Album</p>
                            <p className={styles.footerContentSectionText}>Photo Selection</p>

                        </div>
                        {/* <div >
                            <p>Support</p>
                            <p className='mt-[32px]  text-[#787878]'>+91 9986619796</p>
                            <p className={styles.footerContentSectionText}>hello@memshots.com</p>
                        </div> */}
                        <div >
                            <p>Legal</p>
                            <p
                                onClick={() => {
                                    navigate("/tandc");
                                }}
                                className='mt-[32px]  text-[#787878] cursor-pointer'>Terms & Conditions</p>
                            <p
                                onClick={() => {
                                    navigate("/privacypolicy");
                                }}
                                className={styles.footerContentSectionText}>Privacy policy</p>
                            <p
                                onClick={() => {
                                    navigate("/refund");
                                }}
                                className={styles.footerContentSectionText}>Cancellation & Refund policy</p>
                            <p
                                onClick={() => {
                                    navigate("/shipping");
                                }}
                                className={styles.footerContentSectionText}>Shipping & Delivery policy</p>
                        </div>
                        <div >
                            <p>Social Connection</p>
                            <div className='mt-[32px] flex gap-[16px] '>
                                <a href="https://www.instagram.com/memshots_?igsh=cWo5bHdpdjR5ZzRi" target='_blank' rel="noreferrer" >
                                    <img src={instaLogo} alt="" className='cursor-pointer' />
                                </a>
                                <a href="https://www.linkedin.com/company/memshots/" target='_blank' rel="noreferrer">
                                    <img src={linkedInLogo} alt="" className='cursor-pointer' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerDivSection2}>
                    <p>© 2024 memshots</p>

                </div>
            </div>
        </div>
    )
}

export default Footer