import React, { useRef } from "react";
import styles from "./InvitationPurchase.module.css";
import ReactPlayer from "react-player";

function LiveSection({ section, setTemplateJson, index, preview }) {
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setTemplateJson((prev) => {
      const newArray = prev?.sections;
      const newObject = newArray[index];
      newObject.sectionData[inputRef.current.id] = inputRef.current.value;
      newArray[index] = newObject;
      return {
        ...prev,
        sections: newArray,
      };
    });
  };

  return (
    <div className="px-[20px]">
      {preview ? <div
        style={{
          paddingTop: section?.sectionData?.liveUrl ? (window.innerWidth >= 640 ? "40px" : "30px") : "0",
          paddingBottom: section?.sectionData?.liveUrl ? (window.innerWidth >= 640 ? "60px" : "30px") : "0"
        }}
        className="flex flex-col items-center">
        {section?.sectionData?.liveUrl && <span className="font_bold_18 text-[#FF4545] opacity-[0.9] w-full text-center">Live Broadcast</span>}
        {section?.sectionData?.liveUrl && <div className="mt-[20px] md:mt-[34px] w-full overflow-hidden rounded-[12px] h-[170px] sm:h-[240px] lg:h-[390px]">
          <ReactPlayer
            controls={true}
            width="100%"
            height="100%"
            url={section?.sectionData?.liveUrl}
            style={{ objectFit: "cover" }}
            playsinline
          //   light
          />
        </div>}
      </div> :
        <div className="py-[35px]">
          <div
            className={`${styles.dottedSectionContainer} h-[350px] sm:h-[410px]`}
          >
            <div className={styles.urlInput}>
              <input
                // onChange={handleInputChange}
                ref={inputRef}
                type="text"
                placeholder="Paste video url here & click Add."
                id="liveUrl"
                defaultValue={section?.sectionData?.liveUrl}
              />
              <span onClick={handleInputChange}>Add</span>
            </div>
            {section?.sectionData?.liveUrl && (
              <div className={styles.videoContainer}>
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={section?.sectionData?.liveUrl}
                  style={{ objectFit: "cover" }}
                  playsinline
                // light
                />
              </div>
            )}
            <div className={styles.dottedSectionTitleAbsolute}>
              <p>
                Live Broadcast<span>Optional</span>
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default LiveSection;
