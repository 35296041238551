import React, { useRef } from "react";
import styles from "./InvitationPurchase.module.css";
import ReactPlayer from "react-player";

function VideoSection({ section, setTemplateJson, index, preview }) {
  const inputRef = useRef(null);

  const handleInputChange = () => {
    setTemplateJson((prev) => {
      const newArray = prev?.sections;
      const newObject = newArray[index];
      newObject.sectionData[inputRef.current.id] = inputRef.current.value;
      newArray[index] = newObject;
      return {
        ...prev,
        sections: newArray,
      };
    });
  };

  return (
    <div className=" px-[20px]">
      {preview ? (
        <div
          style={{
            paddingTop: section?.sectionData?.videoUrl
              ? window.innerWidth >= 640
                ? "40px"
                : "30px"
              : "0",
            paddingBottom: section?.sectionData?.videoUrl
              ? window.innerWidth >= 640
                ? "60px"
                : "30px"
              : "0",
          }}
          className="flex flex-col items-center"
        >
          {section?.sectionData?.videoUrl && (
            <span className="font_bold_18 text-[#FF4545] opacity-[0.9] w-full text-center">
              Highlights
            </span>
          )}
          {section?.sectionData?.videoUrl && (
            <div className="mt-[20px] md:mt-[34px] w-full overflow-hidden rounded-[12px] h-[170px] sm:h-[240px] lg:h-[390px]">
              <ReactPlayer
                controls={true}
                width="100%"
                height="100%"
                url={section?.sectionData?.videoUrl}
                style={{ objectFit: "cover" }}
                playsinline
                //   light
              />
            </div>
          )}
        </div>
      ) : (
        <div className="py-[35px]">
          <div
            className={`${styles.dottedSectionContainer} h-[350px] sm:h-[410px]`}
          >
            <div className={styles.urlInput}>
              <input
                // onChange={handleInputChange}
                ref={inputRef}
                type="text"
                placeholder="Paste video url here & click Add."
                id="videoUrl"
                defaultValue={section?.sectionData?.videoUrl}
              />
              <span onClick={handleInputChange}>Add</span>
            </div>
            {section?.sectionData?.videoUrl && (
              <div className={styles.videoContainer}>
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  url={section?.sectionData?.videoUrl}
                  style={{ objectFit: "cover" }}
                  playsinline
                  //   light
                />
              </div>
            )}
            <div className={styles.dottedSectionTitleAbsolute}>
              <p>
                Pre Wedding Video/ Save The Date<span>Optional</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoSection;
