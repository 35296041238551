import React, { useEffect, useState } from "react";
// import { datas } from "../../constants/Constants";
import styles from "./home.module.css";
import axios from "axios";

function Clients({ limit, wrap }) {
  const [data, setData] = useState([]);
  let displayLimit = limit || data?.length;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`/Json/Clients.json`);
      setData(response?.data?.clients);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.clientsDiv}>
        <div className="w-full">
          <p className="font_semibold_32_24 max-w-[600px]">
            Discover who's using{" "}
            <span className="dmSerifRegularItalic font_normal_32_24">
              Memshots
            </span>{" "}
            to capture and share memories.
          </p>
        </div>
        <div
          className={`${styles.clientsScrollSection} no-scrollbar`}
          style={{ flexWrap: wrap || "" }}
        >
          {data?.map((obj, i) => {
            if (i < displayLimit) {
              return (
                <div key={i} className={styles.clientsCards}>
                  <img src={obj?.image} alt="" />
                  <p>{obj?.text}</p>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default Clients;

// <div className="w-[207px] sm:w-[404px] h-[104px] sm:h-[204px]  ">
//                 <img
//                   className={`${
//                     i === 0 ? "object-cover" : "object-contain"
//                   } w-full h-full `}
//                   src={item?.image}
//                   alt=""
//                 />
//               </div>
//               <div className="text-[13px] text-center mt-2">
//                 <p> {item?.text} </p>
//               </div>
