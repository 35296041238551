import React, { useContext } from "react";
import styles from "./InvitationPurchase.module.css";
import { useState } from "react";
import { useRef } from "react";
import deleteIcon from "../../Assets/Images/InitationPurchase/deleteIcon.svg";
import Context from "../../Context";

function Gallery({ section, setTemplateJson, index, setImagesToUpload, preview }) {
    const { uploadedImages, setUploadedImages } = useContext(Context);
    const [displayImages, setDisplayImages] = useState([]);
    const galleryRef = useRef();
    const [galleryLoading, setGalleryLoading] = useState(false);

    const handleGalleryButton = () => {
        galleryRef.current.click();
    };

    const handleGalleyChange = (e) => {
        setGalleryLoading(true);
        const choosedFiles = Array.from(e.target.files);
        const imageUrls = choosedFiles?.map((image) => URL.createObjectURL(image));
        setDisplayImages((prev) => [...prev, ...imageUrls]);
        setUploadedImages((prev) => [...prev, ...choosedFiles]);
        setImagesToUpload((prev) => ({
            ...prev,
            [index]: {
                index: index,
                type: section?.sectionProps?.sectionType,
                images: [
                    ...(prev && prev[index]?.images ? prev[index].images : []),
                    ...choosedFiles,
                ],
            },
        }));
    };

    const handleDelete = async (i) => {
        const updatedFiles = [...uploadedImages];
        updatedFiles.splice(i, 1);
        setUploadedImages(updatedFiles);
        const newDisplayImages = [...displayImages];
        newDisplayImages.splice(i, 1);
        setDisplayImages(newDisplayImages);
        setImagesToUpload((prev) => ({
            ...prev,
            [index]: {
                index: index,
                type: section?.sectionProps?.sectionType,
                images: updatedFiles,
            },
        }));
    };
    return (
        <div className=" px-[20px]">
            {preview ?
                displayImages.length !== 0 && <div
                    style={{
                        paddingTop: displayImages.length !== 0 ? (window.innerWidth >= 640 ? "40px" : "30px") : "0",
                        paddingBottom: displayImages.length !== 0 ? (window.innerWidth >= 640 ? "60px" : "30px") : "0"
                    }}
                    className="w-full flex flex-col items-center">
                    {displayImages.length !== 0 && <span className=" font_bold_18 text-[#FF4545] opacity-[0.9] w-full text-center">Gallery</span>}
                    <div className="mt-[20px] lg:mt-[34px]   flex flex-wrap gap-x-[13px] lg:gap-x-[20px] gap-y-[20px] lg:gap-y-[40px] relative w-full items-center justify-center">
                        {displayImages?.map((obj, i) => {
                            return (
                                <div key={i} className="relative  rounded-[12px] object-cover  ">
                                    <img
                                        style={{
                                            display: galleryLoading ? "none" : "block",
                                        }}
                                        src={displayImages[i]}
                                        alt=""
                                        key={i}
                                        className=" w-[160px] lg:w-[250px] h-[200px] lg:h-[312px] rounded-[12px]  object-cover aspect-square "
                                        onLoad={() => setGalleryLoading(false)}
                                    />
                                    {galleryLoading && (
                                        <div className="w-full h-full flex items-center justify-center text-[#fff] text-[12px] opacity-[0.6]">
                                            <p>Loading...</p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div> :
                <div className="py-[35px]">
                    <div
                        className={`${styles.dottedSectionContainer} py-[30px] sm:py-[25px] min-h-[182px] relative`}
                    >
                        {displayImages.length !== 0 ? (
                            <div className="flex flex-wrap gap-[10px] relative w-full items-center justify-center">
                                {displayImages?.map((obj, i) => {
                                    return (
                                        <div key={i} className="relative bg-[#F6F6F6]  w-full max-w-[140px] rounded-[12px] h-full object-cover aspect-square ">
                                            <img
                                                style={{
                                                    display: galleryLoading ? "none" : "block",
                                                }}
                                                src={displayImages[i]}
                                                alt=""
                                                key={i}
                                                className=" w-full max-w-[140px] rounded-[12px] h-full object-cover aspect-square "
                                                onLoad={() => setGalleryLoading(false)}
                                            />
                                            <img
                                                onClick={() => {
                                                    handleDelete(i);
                                                }}
                                                src={deleteIcon}
                                                alt=""
                                                className="z-[50] w-[24px] h-[24px] absolute top-[10px] right-[10px] cursor-pointer"
                                            />
                                            {galleryLoading && (
                                                <div className="w-full h-full flex items-center justify-center text-[#000] text-[12px] opacity-[0.6]">
                                                    <p>Loading...</p>
                                                </div>
                                            )}
                                        </div>

                                    );
                                })}

                            </div>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: "#F6F6F6",
                                }}
                                className={styles.coverContainerGallery}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <g opacity="0.6">
                                        <path
                                            opacity="0.5"
                                            d="M19 2H5C4.20508 2.00237 3.4434 2.31921 2.8813 2.8813C2.31921 3.4434 2.00237 4.20508 2 5V13.86L5.88 9.98C6.4517 9.43586 7.21074 9.13238 8 9.13238C8.78926 9.13238 9.5483 9.43586 10.12 9.98L12.991 12.867L13.879 11.979C14.4426 11.4185 15.2052 11.1039 16 11.1039C16.7948 11.1039 17.5574 11.4185 18.121 11.979L22 15.86V5C21.9976 4.20508 21.6808 3.4434 21.1187 2.8813C20.5566 2.31921 19.7949 2.00237 19 2Z"
                                            fill="#161616"
                                        />
                                        <path
                                            d="M10.12 9.98C9.5483 9.43586 8.78926 9.13239 8 9.13239C7.21074 9.13239 6.4517 9.43586 5.88 9.98L2 13.86V19C2.00237 19.7949 2.31921 20.5566 2.8813 21.1187C3.4434 21.6808 4.20508 21.9976 5 22H19C19.4034 21.9998 19.8026 21.9182 20.1737 21.7601C20.5449 21.602 20.8803 21.3707 21.16 21.08L10.12 9.98Z"
                                            fill="#161616"
                                        />
                                        <path
                                            opacity="0.25"
                                            d="M22 15.858L18.121 11.979C17.5574 11.4185 16.7949 11.1039 16 11.1039C15.2052 11.1039 14.4426 11.4185 13.879 11.979L12.991 12.867L21.156 21.076C21.698 20.521 22.001 19.776 22 19V15.858Z"
                                            fill="#161616"
                                        />
                                    </g>
                                </svg>
                                <span>Upload your Photos</span>
                                <p>Please select multiple high-quality images for gallery.</p>
                                <button onClick={handleGalleryButton}>Browse Images</button>
                            </div>
                        )}
                        <input
                            ref={galleryRef}
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleGalleyChange}
                            multiple
                        />

                        {uploadedImages.length !== 0 && (
                            <div
                                onClick={handleGalleryButton}
                                className={styles.addMoreGalleryButton}
                            >
                                <span>Add more images</span>
                            </div>
                        )}

                        <div className={styles.dottedSectionTitleAbsolute}>
                            <p>
                                Photo & Video Gallery<span>Optional</span>
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default Gallery;
