import React from "react";
import styles from "../styles.module.css";
import userImageFemale from "../../../Assets/Images/Pitch/userImageFemale.png";
import userImageMale from "../../../Assets/Images/Pitch/userImageMale.png";
import { useNavigate } from "react-router-dom";

function Testimonials({ title, testimonialOne, testimonialTwo }) {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.testimonialsDiv}>
        <p className="font_medium_40_32 text-[#161616] max-w-[706px]">
          {title}
        </p>
        <div className={styles.testimonialsContainer}>
          <TestimonialCards
            userImage={userImageFemale}
            testimonial={testimonialOne}
          />
          <TestimonialCards
            userImage={userImageMale}
            testimonial={testimonialTwo}
          />
        </div>
        <p className="max-w-[496px] mt-[60px] font_normal_16 text-[#161616]">
          Our intuitive platform makes it easy to upload, organize, and share
          best photos – leaving you free to enjoy every moment of any special
          day.
        </p>
        {/* <div className="flex gap-[12px] mt-[30px] lg:gap-[16px]">
          <button
            onClick={() => {
              navigate(`/buynow?from=autograph`);
            }}
            className={styles.tryDemoButton}
          >
            Buy now
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Testimonials;

function TestimonialCards({ userImage, testimonial }) {
  const truncateText = (text, limit) => {
    if (text?.length > limit && window.innerWidth < 1024) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  return (
    <div className={styles.testimonialsCards}>
      <div className={styles.cardsMark}></div>
      <div className={styles.cardsContent}>
        <img src={userImage} alt="" className="" />
        <p className="font_normal_13 text-[#000] max-w-[327px]">
          {testimonial?.length > 180
            ? truncateText(testimonial, 180)
            : testimonial}
        </p>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2666 12.457C14.2666 17.4824 11.9025 21.6702 7.17432 25.0205C6.09359 25.804 5.26953 26.1958 4.70215 26.1958C4.32389 26.1958 4.13477 25.9661 4.13477 25.5068C4.13477 25.0205 4.21582 24.6828 4.37793 24.4937C4.54004 24.2775 4.72917 24.0884 4.94531 23.9263C5.16146 23.7642 5.3776 23.6156 5.59375 23.4805C5.8099 23.3454 6.05306 23.1562 6.32324 22.9131C9.02507 20.4544 10.376 18.2254 10.376 16.2261C10.376 15.0643 9.90316 14.4834 8.95752 14.4834C4.98584 14.4834 3 12.8488 3 9.57959C3 8.30973 3.48633 7.229 4.45898 6.3374C5.45866 5.4458 6.52588 5 7.66064 5C9.76807 5 11.3892 5.83757 12.5239 7.5127C13.6857 9.16081 14.2666 10.8089 14.2666 12.457ZM28.4512 12.457C28.4512 17.6175 26.0736 21.8053 21.3184 25.0205C20.1566 25.804 19.3595 26.1958 18.9272 26.1958C18.522 26.1958 18.3193 25.9661 18.3193 25.5068C18.3193 25.0205 18.4004 24.6828 18.5625 24.4937C18.7246 24.2775 18.9137 24.0884 19.1299 23.9263C19.346 23.7642 19.5622 23.6156 19.7783 23.4805C19.9945 23.3454 20.2376 23.1562 20.5078 22.9131C23.2096 20.4544 24.5605 18.2389 24.5605 16.2666C24.5605 15.0778 24.0607 14.4834 23.061 14.4834C20.3592 14.4834 18.5625 13.6323 17.6709 11.9302C17.3467 11.3088 17.1846 10.4442 17.1846 9.33643C17.1846 8.22868 17.6709 7.229 18.6436 6.3374C19.6432 5.4458 20.724 5 21.8857 5C23.9661 5 25.5737 5.83757 26.7085 7.5127C27.8703 9.16081 28.4512 10.8089 28.4512 12.457Z"
            fill="#9747FF"
          />
        </svg>
      </div>
    </div>
  );
}
