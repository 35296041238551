import React, { useEffect, useRef, useState } from 'react'
import servicesLeftArrow from '../../Assets/Images/servicesLeftArrow.svg'
import servicesRightArrow from '../../Assets/Images/servicesRightArrow.svg'

function Services({ hotelData }) {
    const cardRefs = useRef([]);
    const sliderRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const slider = sliderRef.current;
        const handleScroll = () => {
            const sliderWidth = slider.offsetWidth;
            const sliderLeft = slider.getBoundingClientRect().left;
            const activeSlide = Array.from(slider.children).find((slide) => {
                const slideLeft = slide.getBoundingClientRect().left;
                return slideLeft >= sliderLeft;
            });

            setActiveIndex(
                activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
            );
        };
        slider?.addEventListener("scroll", handleScroll);
        return () => slider?.removeEventListener("scroll", handleScroll);
    }, []);

    const handleNext = () => {
        let currentIndex = activeIndex;
        let nextIndex;
        if (currentIndex + 1 === hotelData?.services?.length) {
            nextIndex = 0;
        } else {
            nextIndex = currentIndex + 2;
        }
        setActiveIndex(nextIndex);
        if (cardRefs.current[nextIndex]) {
            cardRefs.current[nextIndex].scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });
        }
    };

    const handlePrevious = () => {
        let currentIndex = activeIndex;
        let prevIndex;
        if (currentIndex === 0) {
            prevIndex = hotelData?.services?.length - 1;
        } else {
            prevIndex = currentIndex - 1;
        }
        setActiveIndex(prevIndex);
        if (cardRefs.current[prevIndex]) {
            cardRefs.current[prevIndex].scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });
        }
    };

    return (
        <div className='containFull px-[20px] lg:px-[86px] mt-[30px] lg:mt-[60px]'>
            <p className='font_semibold_40_24 clashdisplay_semibold text-[#1F2797] mb-[16px] lg:mb-[53px]' >{hotelData?.hotelName} Services</p>
            <div className='w-full relative'>
            <div
                ref={sliderRef}
                className='w-full mx-auto  flex overflow-y-hidden overflow-x-auto no-scrollbar '>
                {
                    hotelData?.services?.map((obj, i) => {
                        return (
                            <div
                                key={i}
                                ref={(element) => {
                                    cardRefs.current[i] = element;
                                }}
                                className='shrink-0 flex flex-col gap-[16px] w-[272px] mx-[12px] lg:mx-[30px] first:ml-0 last:mr-0'>
                                <div>
                                    <img src={obj?.image} alt="" className='rounded-[8px] object-cover w-[272px] h-[272px]' />
                                </div>
                                <div className=''>
                                    <p className='clashdisplay_semibold font_semibold_21 text-[#1F2797] text-left '>{obj?.text}</p>
                                </div>
                            </div>
                        )
                    })
                }
                <img
                    onClick={handlePrevious}
                    src={servicesLeftArrow}
                    alt=""
                    className='absolute left-0 verticalCenterAlign cursor-pointer ml-[-20px] hidden lg:block ' />
                <img
                    onClick={handleNext}
                    src={servicesRightArrow}
                    alt=""
                    className='absolute right-0 verticalCenterAlign cursor-pointer mr-[-20px] hidden lg:block ' />
            </div>
            </div>
        </div>

    )
}

export default Services