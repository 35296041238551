import { useContext, useEffect, useState } from "react";
import styles from "./styles/SignupPage.module.css";
import { useLocation, useNavigate, useParams } from "react-router";
import Context from "../../Context";
import * as _ from "lodash";
import googleLogo from "../../Assets/Images/googleLogo.svg";
import LoadingFromCenter from "../../Components/LoadingFromCenter/LoadingFromCenter";
import ForgotPasswordPopup from "../../Components/ForgotPasswordPopup/ForgotPasswordPopup";
import closeIcon from "../../Assets/Images/closeIconSignup.svg";
import AuthContext from "../../AuthContext";

function SignupPage({ setOpen, open }) {
  const { mobileNumberHide } = useContext(Context);
  const {
    numberVerificationLoading,
    enterUserDisplayName,
    updateUser,
    googleSignIn,
    pageLoading,
    emailError,
    setEmailError,
    emailSignIn,
    emailLogin,
  } = useContext(AuthContext);
  const [emailObj, setEmailObj] = useState({});
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const [showEmailLogin, setShowEmailLogin] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleInputChangeEmail = (e) => {
    e.preventDefault();
    setEmailObj((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleSubmitEmailPassword = (e, type) => {
    e.preventDefault();
    setEmailError("");
    if (_.isEmpty(emailObj.name) && type == "signin") {
      setEmailError("Please Enter your Name");
      return;
    }
    if (_.isEmpty(emailObj.email)) {
      setEmailError("Please Enter Email address");
      return;
    }
    if (_.isEmpty(emailObj.password)) {
      setEmailError("Please Enter a password");
      return;
    }
    switch (type) {
      case "signin":
        emailSignIn(emailObj?.name, emailObj?.email, emailObj?.password);
        break;
      default:
        emailLogin(emailObj?.email, emailObj?.password);
        break;
    }
  };

  return (
    <>
      {forgotPasswordPopup && (
        <ForgotPasswordPopup setPopup={setForgotPasswordPopup} />
      )}
      <div
        className={`${
          open ? `coming ${styles.theGrtAbso}` : `out ${styles.theGrtAbso}`
        } z-[200]`}
      >
        <div className="w-full h-full  ">
          <div className="max-w-[390px] boxSh bg-[#FFFFFF] rounded-[20px] my-[20px] mx-auto py-[34px] ">
            {pageLoading && <LoadingFromCenter />}
            <div className="w-full px-[20px] flex justify-end mt-[12px]">
              {setOpen ? (
                <img
                  onClick={() => {
                    setOpen(false);
                  }}
                  src={closeIcon}
                  alt=""
                  className="cursor-pointer"
                />
              ) : (
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  src={closeIcon}
                  alt=""
                  className="cursor-pointer"
                />
              )}
            </div>
            <div className="w-full flex justify-center px-[20px] mt-[20px]">
              <p className="text-[26px] leading-[32px] font-bold text-[#3D3C3C] text-center">
                {showEmailLogin ? "Hello again!" : "Create an Account"}
              </p>
            </div>
            <p className="px-[20px] mt-[16px] mb-[28px] text-[16px] leading-[22px] text-[#000] text-center font-normal">
              {showEmailLogin
                ? "Welcome back, you’ve been missed!"
                : "Welcome, lets explore!"}{" "}
            </p>
            {/* Create new account */}
            {!showEmailLogin && (
              <div className="px-[20px]">
                <div className="mb-[32px]">
                  <div className="flex items-center relative">
                    <div className={styles.mobileLogin}>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="text-[16px] leading-[22px] font-medium mb-[20px]"
                        onChange={handleInputChangeEmail}
                        value={emailObj?.name}
                      />
                      <input
                        type="email"
                        id="email"
                        name="number"
                        placeholder="Email"
                        className="text-[16px] leading-[22px] font-medium mb-[20px]"
                        onChange={handleInputChangeEmail}
                        value={emailObj?.email}
                      />
                      <input
                        type="password"
                        id="password"
                        name="number"
                        placeholder="Password"
                        className="text-[16px] leading-[22px] font-medium"
                        onChange={handleInputChangeEmail}
                        value={emailObj?.password}
                      />
                    </div>
                  </div>
                  {emailError && (
                    <div className="text-small text-left w-[100%] my-[10px]">
                      <span className="text-red-600">*</span>
                      {emailError}
                    </div>
                  )}
                </div>
                <button
                  className={`${styles.button} text-regular-medium bg-[#DB504ACC]`}
                  onClick={(e) => handleSubmitEmailPassword(e, "signin")}
                  disabled={numberVerificationLoading || pageLoading}
                >
                  Sign in
                </button>
              </div>
            )}
            {/* Log in */}
            {showEmailLogin && (
              <div className="px-[20px]">
                <div className="mb-[32px]">
                  <div className="flex items-center relative">
                    <div className={styles.mobileLogin}>
                      <input
                        type="email"
                        id="email"
                        name="number"
                        placeholder="Email"
                        className="text-[16px] leading-[22px] font-medium mb-[20px]"
                        onChange={handleInputChangeEmail}
                        value={emailObj?.email}
                      />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="text-[16px] leading-[22px] font-medium"
                        onChange={handleInputChangeEmail}
                        value={emailObj?.password}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-beween">
                    {emailError && (
                      <div className="text-[13px] leading-[18px]  text-left w-[100%] my-[10px]">
                        <span className="text-red-600">*</span>
                        {emailError}
                      </div>
                    )}
                    <p
                      className="text-right w-full my-[10px] text-[13px] leading-[18px]  cursor-pointer"
                      onClick={() => {
                        setEmailError("");
                        setForgotPasswordPopup(true);
                      }}
                    >
                      Forgot your password?
                    </p>
                  </div>
                </div>
                <button
                  className={`${styles.button} text-regular-medium bg-[#DB504ACC]`}
                  onClick={(e) => handleSubmitEmailPassword(e, "login")}
                  disabled={numberVerificationLoading || pageLoading}
                >
                  Log in
                </button>
              </div>
            )}
            <p className="my-[18px] text-[14px] leading-[18px] text-black w-full text-center ">
              Or connect with
            </p>
            {!enterUserDisplayName?.display && (
              <div className="px-[20px] mb-[37px]">
                {mobileNumberHide && (
                  <p className="text-regular my-[28px]">OR</p>
                )}
                <button
                  className={styles.signGoog}
                  onClick={googleSignIn}
                  disabled={pageLoading}
                >
                  <img src={googleLogo} alt="" />
                  <p>Sign in with Google</p>
                </button>
              </div>
            )}
            {showEmailLogin ? (
              <p
                className="mb-[10px] text-small text-center cursor-pointer"
                onClick={() => setShowEmailLogin(false)}
              >
                Don't have an account?
                <span className="ml-[5px] underline text-[#3075FC]">
                  Create one
                </span>
              </p>
            ) : (
              <p
                className="mb-[10px] text-small text-center cursor-pointer"
                onClick={() => {
                  setEmailError("");
                  setShowEmailLogin(true);
                }}
              >
                Already have an account?{" "}
                <span className="ml-[5px]  underline text-[#3075FC]">
                  Log in
                </span>
              </p>
            )}
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
}

export default SignupPage;
