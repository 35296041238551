import React from "react";
import styles from "./home.module.css";
import sectionImage from "../../Assets/Images/Home/LinkSection.png";

function CustomUrl() {
  return (
    <div className={styles.containerPink}>
      <div className={styles.customUrlDiv}>
        <div className={styles.customUrlSection1}>
          <div>
            <p className="font_normal_16 opacity-[0.6]">
              Start with a link, End with smile.
            </p>
            <p className="mt-[24px] font_medium_52_32">
              memshots.com/
              <span className="font_normal_52_32 text-[#A3A3A3]">foryou</span>
            </p>
          </div>
          <div>
            <p className="font_normal_13 opacity-[0.6]">
              Customize this link with any name you like, it can be “ your
              friends name, party name, memories... anything you want to share
              with..
            </p>
          </div>
        </div>
        <div className={styles.customUrlSection2}>
          <img src={sectionImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default CustomUrl;
