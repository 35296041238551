import React, { useEffect, } from "react";
import { useLocation } from 'react-router-dom';
const PaymentThirdParty = () => {
    let data = useLocation();
    const transactUrl = data?.search?.split("?gatewayresp=")[1]
    useEffect(() => {
        window.location.href = decodeURIComponent(transactUrl)
    }, []);
    return (
        <div>
            <h1>Shootmates</h1>
        </div>
    )
}
export default PaymentThirdParty