import React, { useState } from 'react'
import playIcon from '../../Assets/Images/playIcon.svg'
import GalleryPopup from '../Hotels/GalleryPopup'


function Gallery({ photographerData }) {
    const [galleryPopup, setGalleryPopup] = useState(false)
    const [mediaIndex, setMediaIndex] = useState("")
    return (
        <div className='containFull px-[20px] lg:px-[86px] mt-[30px] lg:mt-[60px] pb-[60px] lg:pb-[80px]'>
            {galleryPopup && <GalleryPopup setGalleryPopup={setGalleryPopup} data={photographerData?.gallery} imageIndex={mediaIndex} />}
            <p className='font_bold_28_21 text-[#FFF] mb-[15px] lg:mb-[30px]'>My Recent Photos</p>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[20px] md:gap-x-[40px] lg:gap-x-[60px] gap-y-[30px]'>
                {photographerData?.gallery?.map((obj, i) => {
                    return (
                        <div
                            key={i}
                            className='w-full h-full aspect-square hover:opacity-[0.7] cursor-pointer relative'>
                            <img
                                onClick={() => {
                                    setMediaIndex(i)
                                    setGalleryPopup(true)
                                }}
                                src={obj?.thumbnailImage}
                                alt=""
                                className='object-cover w-full h-full' />
                            {obj?.type === 'video' && <img
                                src={playIcon}
                                alt=""
                                className='absolute centeralign' />}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Gallery