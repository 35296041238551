import React from "react";
import styles from "../styles.module.css";

function Cover({ title, content }) {
  return (
    <div className={styles.container}>
      <div className={styles.coverDiv}>
        <span className="font_medium_16 text-[#F96149]">LET'S GET STARTED</span>
        <span className="mt-[10px] font_semibold_52_32 text-[#161616]">
          {title}
        </span>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Cover;
