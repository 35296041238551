import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import FooterShort from "../Components/FooterShort";

function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="containFull">
      <Navbar />
      <div className="w-full h-full px-[5.26%] lg:px-[8.33%]  mb-[60px] mt-[42px] lg:mt-[84px] text-[#000] min-h-[75vh]">
        <p className="mb-[30px]  font_semibold_40_24">
          Shipping & Delivery Policy
        </p>
        <p className="mb-[30px] text-[16px] lg:text-[21px]">
          Shipping is not applicable for business.
        </p>
      </div>
      <FooterShort />
    </div>
  );
}

export default Shipping;
