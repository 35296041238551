import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import { useUserAuth } from "../userContext/UserContext";
import AuthContext from "../AuthContext";
import SignupPage from "../Pages/Signup/SignupPage";

function ProtectedRoute(props) {
  const { Component } = props;
  //   const navigate = useNavigate();
  const { currentUser, userLoading } = useContext(AuthContext);
  const [popUp, setPopUp] = useState(false);
  //   const location = useLocation();
  //   const [searchParams, setSearchParams] = useSearchParams();


  //   useEffect(() => {
  //     if (!currentUser && !userLoading) {

  //       //   const paramsObject = Object.fromEntries(searchParams.entries());
  //       //   navigate("/", {
  //       //     state: {
  //       //       previousLocation: location?.pathname,
  //       //       searchParams: paramsObject,
  //       //     },
  //       //   });

  //     }
  //   });

  return (
    <div className="w-full h-full">
      {!currentUser && !userLoading && (
        <SignupPage  open={true} />
      )}
      {!userLoading && <Component />}
    </div>
  );
}

export default ProtectedRoute;
