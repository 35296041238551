import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Landing from "./Hotels/Landing";
import axios from "axios";
import LandingStreetPhotography from "./StreetPhotographer/LandingStreetPhotography";

function Templates() {
  const { slugId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/Json/${slugId}.json`);
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="containFull min-h-[100vh] flex justify-center items-center">
          <p className="font_bold_24 clashdisplay_bold text-[#1F2797]">
            Loading...
          </p>
        </div>
      ) : (
        <div>
          {data?.type === "hotel" ? (
            <Landing data={data} />
          ) : data?.type === "streetPhotography" ? (
            <LandingStreetPhotography data={data} />
          ) : (
            <div className="containFull min-h-[100vh] flex justify-center items-center">
              <p className="font_bold_24 clashdisplay_bold text-[#1F2797]">
                Please enter valid url
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Templates;
