import React from "react";
import styles from "./home.module.css";
import albumImage from "../../Assets/Images/Home/albumImage.jpg";
import goToIcon from "../../Assets/Images/Home/goToIcon.svg";
import { Link } from "react-router-dom";

function SignatureAlbum() {
  return (
    <div className={styles.container}>
      <div className={styles.faceDetectionDiv}>
        <div className={styles.faceDetectionTitle}>
          <p className="font_semibold_72_32 ">Signature Album</p>
          <p className="font_normal_16 opacity-[0.8] max-w-[436px]">
            Create, share, and treasure your memories with our Signature Album.
            Easily organize and access your favorite photos anytime, anywhere.
          </p>

          {/* <div className={styles.albumAbsolute}>
            <img src="shootmatesLogo.png" alt="" />
            <p>Memshots for Photographers</p>
          </div> */}
        </div>
        <img
          src={albumImage}
          alt=""
          className="w-full shrink-0  object-cover my-[40px] rounded-[16px]"
        />
        <div className={styles.albumButtonsSection}>
          <p className="font_bold_24 max-w-[580px]">
            Create a signature album for your next event.
          </p>
          <div className="flex gap-[20px]">
            <a
              href="https://photographers.memshots.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
              >
                <span>Explore</span>
                <img src={goToIcon} alt="" />
              </button>
            </a>
            <Link to={"https://app.memshots.com/amayaandayan?from=memshotsdemo"}>
              <button className={styles.buyNowButton}>View Sample</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignatureAlbum;
