import React, { useEffect } from 'react'
import styles from "./UploadingPopup.module.css";
import invitationConfirmGraphic from '../Assets/Images/InitationPurchase/invitationConfirmGraphic.png'


function InvitationPurachseConfirmPopup({ closePopup, onHandleBuy }) {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);
    return (
        <div className={styles.popUpContainer}>
            <div className={` ${styles.contentBox}`}>
                <img src={invitationConfirmGraphic} alt='' className='w-full max-h-[134px] object-cover' />
                <div className='p-[24px] text-center text-[#000]'>
                    <p className="font_semibold_18 ">Your details will be uploaded and will be redirecetd to payment gateway.</p>
                    <p className='mt-[24px] font_normal_13'>Kindly confirm all your details provided. Editing invitation can be done later in profile page</p>
                </div>
                <div className='w-full h-[1px] bg-[#000] opacity-[0.2]'></div>
                <div className='p-[24px] flex flex-col gap-[12px] w-full'>
                    <button className={styles.tryDemoButton} onClick={onHandleBuy} >
                        <p>Continue Purchase</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M3.33355 7.33344H10.7802L7.52688 4.08011C7.26688 3.82011 7.26688 3.39344 7.52688 3.13344C7.58856 3.07164 7.66181 3.0226 7.74246 2.98915C7.82311 2.9557 7.90957 2.93848 7.99688 2.93848C8.08419 2.93848 8.17065 2.9557 8.2513 2.98915C8.33194 3.0226 8.4052 3.07164 8.46688 3.13344L12.8602 7.52677C12.922 7.58845 12.971 7.66171 13.0045 7.74236C13.038 7.82301 13.0552 7.90946 13.0552 7.99677C13.0552 8.08408 13.038 8.17054 13.0045 8.25119C12.971 8.33184 12.922 8.4051 12.8602 8.46677L8.46688 12.8601C8.40516 12.9218 8.33188 12.9708 8.25124 13.0042C8.1706 13.0376 8.08417 13.0548 7.99688 13.0548C7.90959 13.0548 7.82316 13.0376 7.74252 13.0042C7.66187 12.9708 7.5886 12.9218 7.52688 12.8601C7.46516 12.7984 7.4162 12.7251 7.3828 12.6445C7.34939 12.5638 7.3322 12.4774 7.3322 12.3901C7.3322 12.3028 7.34939 12.2164 7.3828 12.1357C7.4162 12.0551 7.46516 11.9818 7.52688 11.9201L10.7802 8.66677H3.33355C2.96688 8.66677 2.66688 8.36677 2.66688 8.00011C2.66688 7.63344 2.96688 7.33344 3.33355 7.33344Z" fill="#F9F9F9" />
                        </svg>
                    </button>
                    <button className={styles.buyNowButton} onClick={() => closePopup(false)}>Review Again</button>
                </div>


            </div>
        </div>
    )
}

export default InvitationPurachseConfirmPopup