import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../AuthContext";
import styles from "./InvitationPurchase.module.css";
import Navbar from "../../Components/Navbar";
import CoverImage from "./CoverImageAndTitle";
import DateTimeVenue from "./DateTimeVenue";
import LiveSection from "./LiveSection";
import VideoSection from "./VideoSection";
import Gallery from "./Gallery";
import ThankingSection from "./ThankingSection";
import axios from "axios";
import { ref, uploadBytesResumable } from "firebase/storage";
import { invitationImageStorage } from "../../Store/firebase-config";
import UploadingPopup from "../../Components/UploadingPopup";
import InvitationPurachseConfirmPopup from "../../Components/InvitationPurachseConfirmPopup";
import Context from "../../Context";
import NavbarNew from "../../Components/NavbarNew";

function TemplatePurchase() {
  const { googleSignOut, currentUser, userIdToken, contextUserId, verifyUser } =
    useContext(AuthContext);
  const { title } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [templateJson, setTemplateJson] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState({});
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (currentUser?.isAnonymous) {
      googleSignOut();
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    verifyUser();
  }, []);

  const getTemplateData = async () => {
    setDataLoading(true);
    try {
      const config = {
        method: "GET",
        url: "https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshot/album/invitationtemplate?templateType=INVITATION",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const templateData = await axios(config);
      if (templateData?.data.error === false) {
        setTemplateJson(templateData.data.templates[0]);
        setDataLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentUser) getTemplateData();
  }, [currentUser]);

  function handlePreview() {
    setPreview((prev) => !prev);
    window.scrollTo(0, 0);
  }

  function handleBuy() {
    setConfirmPopup(false);
    uploadGalleryImages();
  }

  const uploadGalleryImages = async () => {
    setUploadLoading(true);
    if (Object.keys(imagesToUpload).length === 0) {
      formSubmit(templateJson);
      return;
    }
    try {
      let dataToTransfer = templateJson;
      await Promise.all(
        Object.entries(imagesToUpload)?.map(async (obj, index) => {
          let mediaArray = [];
          // let key = obj[0]
          let value = obj[1];
          for (let i = 0; i < value?.images?.length; i++) {
            const file = value?.images[i];
            let randomId = Math.floor(Date.now() * Math.random());
            const fileName = `${file?.name}`;
            let faceFile = `${randomId}_${fileName}`;
            const storageRef = ref(
              invitationImageStorage,
              `${contextUserId}/${faceFile}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed", (snapshot) => {
              const uploadprogress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[index] = {
                  progress: uploadprogress,
                  type: value?.type,
                };
                return newProgress;
              });
            });
            await uploadTask;
            const url =
              `https://storage.googleapis.com/memshots_invitation/${contextUserId}/` +
              faceFile;
            if (value?.type === "GALLERY") {
              mediaArray.push({ url });
            } else mediaArray.push(url);
          }
          if (value?.type === "COVERIMAGE" || value?.type === "THANKING") {
            dataToTransfer.sections[value?.index].sectionData.coverImage =
              mediaArray[0];
          } else if (value?.type === "GALLERY") {
            dataToTransfer.sections[value?.index].sectionData.media =
              mediaArray;
          }
        })
      );
      return formSubmit(dataToTransfer);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formSubmit = async (dataToTransfer) => {
    let apiData = { ...dataToTransfer, albumName: title };
    try {
      let config = {
        method: "POST",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshot/album/createinvitation/${apiData?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: apiData,
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        paymentInitialise(response?.data?.albumId);
      }
    } catch (e) {
      console.log(e);
      setUploadLoading(false);
    }
  };

  const paymentInitialise = async (albumId) => {
    try {
      let config = {
        method: "POST",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshot/album/initialise_payment/invitation/${albumId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        window.location.href =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo?.url;
        setUploadLoading(false);
      }
    } catch (err) {
      console.log(err);
      setUploadLoading(false);
    }
  };

  const components = (section, i) => {
    let type = section?.sectionProps?.sectionType;
    switch (type) {
      case "COVERIMAGE": {
        return (
          <CoverImage
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "VENUE": {
        return (
          <DateTimeVenue
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "LIVE": {
        return (
          <LiveSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "TEASER": {
        return (
          <VideoSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "GALLERY": {
        return (
          <Gallery
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "THANKING": {
        return (
          <ThankingSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="containFull">
      {uploadLoading && <UploadingPopup progress={progress} />}
      {confirmPopup && (
        <InvitationPurachseConfirmPopup
          closePopup={setConfirmPopup}
          onHandleBuy={handleBuy}
        />
      )}
      <NavbarNew />
      <div className={styles.parentContainer}>
        <div className={styles.navbarContainer}>
          <div className={styles.navbar}>
            <span className="font_semibold_21 hidden lg:block shrink-0 mr-[20px]">
              Create an Invitation
            </span>
            <button onClick={handlePreview} className={styles.buyNowButton}>
              {preview ? "Close Preview" : "Show Preview"}
            </button>
            <div className="w-full flex justify-end">
              <span className="font_medium_13">₹ 1000</span>
              <button
                disabled={dataLoading}
                onClick={() => setConfirmPopup(true)}
                className={styles.buyNowBlackButton}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
        {dataLoading ? (
          <div className="w-full h-full min-h-[400px] flex justify-center items-center opacity-[0.6]">
            Loading...
          </div>
        ) : (
          <div className={styles.templateContainer}>
            {templateJson?.sections?.map((obj, i) => {
              return components(obj, i);
            })}
            {/* <div className={styles.containerWhiteSection}>
                        <CoverImage templateJson={templateJson} setTemplateJson={setTemplateJson} />
                        <DateTimeVenue templateJson={templateJson} setTemplateJson={setTemplateJson} />
                        <LiveSection templateJson={templateJson} />
                        <VideoSection templateJson={templateJson} />
                        <Gallery templateJson={templateJson} />
                    </div>
                    <div className={styles.containerBlackSection} >
                        <ThankingSection templateJson={templateJson} />
                    </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default TemplatePurchase;
