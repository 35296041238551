import React from "react";
import "./LoadingFromCenter.css";

export default function LoadingFromCenter() {
  return (
    <div className="loading">
      <div className="loading-bar"></div>
    </div>
  );
}
