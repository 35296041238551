import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Cover from "../Components/Pitch/Gifts/Cover";
import CoverImage from "../Components/Pitch/Gifts/CoverImage";
import Testimonials from "../Components/Pitch/Gifts/Testimonials";
import Products from "../Components/Pitch/Gifts/ProductsForGifts";
import backToTop from "../Assets/Images/Home/backToTop.svg";
import Features from "../Components/Pitch/Gifts/Features";
import FooterShort from "../Components/FooterShort";
import imageLeft from "../Assets/Images/Pitch/restaurantsGrpahicsImgLeft.png";
import imageRight from "../Assets/Images/Pitch/restaurantsGrpahicsImgRight.png";
import ProductsForRestaurants from "../Components/Pitch/Gifts/ProductsForRestaurants";
import NavbarNew from "../Components/NavbarNew";

function PitchForRestaurants() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="containFull cursor-default">
      <NavbarNew />
      <Cover
        title={"Memshots for Restaurants"}
        content={`At Memshots, we're passionate about crafting unforgettable experiences for guests in the hospitality industry. Our cutting-edge solutions are designed exclusively for hotels and restaurants, aimed at elevating service standards and ensuring unparalleled guest satisfaction.`}
      />
      <CoverImage imageLeft={imageLeft} imageRight={imageRight} />
      <ProductsForRestaurants />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Testimonials
        title={"Join Memshots Today: Elevate Your Hospitality Experience."}
        testimonialOne={`"As a restaurant owner, it's the ultimate review book, capturing unforgettable dining moments with heartfelt sentiment. Memshots, thank you for preserving our memories forever" - James`}
        testimonialTwo={`"Memshots Shared Memories Album made our event extra special. We'll definitely come back to make more memories. Thanks, Memshots" - Abhinav`}
      />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Features />
      <FooterShort />
      <div
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        className="fixed bottom-[35px] right-[10px] cursor-pointer"
      >
        <img
          src={backToTop}
          alt=""
          className="w-[32px] lg:w-[52px] h-[32px] lg:h-[52px] backToTopShadow rounded-full"
        />
      </div>
    </div>
  );
}

export default PitchForRestaurants;
