import React, { createContext, useEffect, useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { authentication } from "./Store/firebase-config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [previousLocation, setPreviousLocation] = useState();
  const [userIdToken, setUserIdToken] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [guestModeEnabled, setGuestModeEnabled] = useState(true);
  const [contextUserId, setContextUserId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [emailAccountCreated, setEmailAccountCreated] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [gmailLoggedIn, setGmailLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    setUrlPath(window.location.href);
    setContextUserId(localStorage.getItem("userId"));
  }, []);

  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setUserLoading(false);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  //Verify User
  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {}
  };

  //Google SigIn
  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setPageLoading(true);
      const login = await signInWithPopup(authentication, provider);
      let config = {};
      config = {
        method: "post",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotUser/api/memshot/users`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
        // data: faceLoginData
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setGmailLoggedIn(true);
        setContextUserId(loginCheck.data?.userId);
        setGuestModeEnabled(false);
        navigate(`${previousLocation}`);
        setPageLoading(false);
        // window.location.href = previousLocation
      } else {
        setPageLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setPageLoading(false);
    }
  };

  //Google SignOut
  const googleSignOut = async () => {
    signOut(authentication)
      .then(() => {
        navigate(window.location.pathname);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //email verification
  const emailVerification = async (user) => {
    try {
      setPageLoading(true);
      const response = await sendEmailVerification(user);

      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };

  //email signin
  const emailSignIn = async (name, email, password) => {
    try {
      setPageLoading(true);
      const response = await createUserWithEmailAndPassword(
        authentication,
        email,
        password
      );
      const updateUserProfile = await updateProfile(response?.user, {
        displayName: name,
      });
      let config = {
        method: "post",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotUser/api/memshot/users`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
        data: { name },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setNewUserId(loginCheck.data?.userId);
        setGuestModeEnabled(false);
        setEmailAccountCreated(true);
        // if (location?.state?.previousLocation) {
        //   navigate(
        //     {
        //       pathname: location?.state?.previousLocation,
        //       search: `${searchParams ? createSearchParams(searchParams) : ""}`,
        //     },
        //     { state: location?.state }
        //   );
        // } else {
        //   navigate(
        //     {
        //       pathname: "/",
        //       search: `${searchParams ? createSearchParams(searchParams) : ""}`,
        //     },
        //     { state: location?.state }
        //   );
        // }
        navigate(`${previousLocation}`);
        setPageLoading(false);
        emailVerification(response?.user);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };

  //email login
  const emailLogin = async (email, password) => {
    try {
      setPageLoading(true);
      const response = await signInWithEmailAndPassword(
        authentication,
        email,
        password
      );
      let config = {
        method: "post",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotUser/api/memshot/users`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setNewUserId(loginCheck.data?.userId);
        setGuestModeEnabled(false);
        // if (location?.state?.previousLocation) {
        //   navigate(
        //     {
        //       pathname: location?.state?.previousLocation,
        //       search: `${searchParams ? createSearchParams(searchParams) : ""}`,
        //     },
        //     { state: location?.state }
        //   );
        // } else {
        //   navigate(
        //     {
        //       pathname: "/",
        //       search: `${searchParams ? createSearchParams(searchParams) : ""}`,
        //     },
        //     { state: location?.state }
        //   );
        // }
        navigate(`${previousLocation}`);
        setPageLoading(false);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
    }
  };

  //email login password reset
  const emailPasswordReset = async (email) => {
    try {
      setPageLoading(true);
      const response = await sendPasswordResetEmail(authentication, email);
      setPageLoading(false);
      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        previousLocation,
        setPreviousLocation,
        userIdToken,
        setUserIdToken,
        pageLoading,
        setPageLoading,
        currentUser,
        setCurrentUser,
        guestModeEnabled,
        setGuestModeEnabled,
        contextUserId,
        setContextUserId,
        emailError,
        setEmailError,
        newUserId,
        setNewUserId,
        emailAccountCreated,
        setEmailAccountCreated,
        urlPath,
        setUrlPath,
        gmailLoggedIn,
        setGmailLoggedIn,
        loading,
        setLoading,
        verifyUser,
        googleSignIn,
        googleSignOut,
        emailVerification,
        emailSignIn,
        emailLogin,
        emailPasswordReset,
        userLoading,
         setUserLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
